import { Link, Image } from '@sitecore-jss/sitecore-jss-react';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import FinancialSolutions from './FinancialSolutions';

const SearchMenu = ({ activeTab, searchShortcuts, relatedReads, dictionary }) => {
  const TrendingProducts = ({ items }) => {
    return (
      <div className="popular_search">
        <span className="popular-search-heading">{items.heading.value}</span>
        <div className="pop_search_tabs">
          {items?.children?.map((x) => {
            return (
              <div className="search_tab_wrapper" key={x.label.value}>
                <a href={`${x.link.url}`}>
                  <Image field={x.icon} editable loading="lazy" />
                  <div>
                    <span>{x.label.value}</span>
                    <p>{x.subtitle.value}</p>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const SearchTags = ({ items }) => {
    return (
      <div className="popular_search">
        <span className="popular-search-heading">{items.heading.value}</span>
        <ul className="pop_search_tags">
          {items?.children?.map((x) => {
            return (
              <li key={x.label.value}>
                <a href={x.link.url}>
                  {x.label.value}{' '}
                  <Image field={items.popularSearchListType.targetItem?.icon} editable loading="lazy" />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  return (
    <div
      className={`search_result_wrapper ${activeTab === 'searchmenu' ? 'search_result_open' : ''}`}
      id="search_wrapper_id"
    >
      <div className="search_result_grid">
        <div className="search_result_main_Wrapper">
          <div className="search_input_Wrapper">
            <input
              type="text"
              placeholder={dictionary?.SearchBoxPlaceholder}
              className="form-control"
            
            />
            <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/cross.svg" className="close_search" id="close_search_btn" loading="lazy" />
          </div>
          <div className="search_results_keywords">
            {searchShortcuts?.map((z) => {
              if (z.popularSearchListType.targetItem.cssclass.value === 'pop_search_tags') {
                return (
                  <SearchTags
                    key={z.popularSearchListType.targetItem.cssclass.value}
                    items={z}
                  ></SearchTags>
                );
              } else if (z.popularSearchListType.targetItem.cssclass.value === 'pop_search_tabs') {
                return (
                  <TrendingProducts
                    key={z.popularSearchListType.targetItem.cssclass.value}
                    items={z}
                  ></TrendingProducts>
                );
              }
            })}
            <div className="popular_search">
              <span className="popular-search-heading">Popular Searches</span>
              <ul className="pop_search_tags">
                <li>
                  <a href="">
                    ABSLI Digishield Plan <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_most_visited.svg" loading="lazy" />
                  </a>
                </li>
                <li>
                  <a href="">
                    ABSLI Child Future Assured Plan <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_most_visited.svg" loading="lazy" />
                  </a>
                </li>
                <li>
                  <a href="">
                    ABSLI Fortune Elite Plan <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_most_visited.svg" loading="lazy" />
                  </a>
                </li>
                <li>
                  <a href="">
                    ABSLI Guaranteed Annuity Plus <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_most_visited.svg" loading="lazy" />
                  </a>
                </li>
              </ul>
              <ul className="pop_search_tags">
                <li>
                  <a href="">
                    ABSLI Nishchit Aayush Plan <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_most_visited.svg" loading="lazy" />
                  </a>
                </li>
                <li>
                  <a href="">
                    ABSLI Assured Savings Plan <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_most_visited.svg" loading="lazy" />
                  </a>
                </li>
                <li>
                  <a href="">
                    ABSLI Fixed Maturity Plan <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_most_visited.svg" loading="lazy" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="our_financial_solutions_wrapper">
          <FinancialSolutions />
          <div className="nav_related_reads">
            {relatedReads && (
              <span className="financial-heading-megamenu">{relatedReads[0]?.heading?.value}</span>
            )}
            <div className="nav_related_reads_slider_Wrapper">
              <div className="swiper nav_related_reads_slider">
                <div className="swiper-wrapper">
                  {relatedReads &&
                    relatedReads[0]?.relatedReads.map((z) => {
                      return (
                        <div className="swiper-slide" key={z.id}>
                          <a href={z?.ctaLink?.url}>
                            <Image field={z?.bgImage} className="w-100" editable alt="img" loading="lazy" />
                            <div className="rr_text">
                              <span className="rr_text_heading">{z?.heading?.value}</span>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                </div>
                <div className="swiper-pagination nav_related_reads_pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SearchMenu);
