import React, { useState, useEffect, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import GraphQLData from '../../lib/GraphQLData';
import { loader as gqlLoader } from 'graphql.macro';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import Countdown from './Countdown';

const TickerQuery = gqlLoader('./Ticker.graphql');

const Ticker = (props) => {
  const [ticker, setTicker] = useState(true);
  const [tickers, setTickers] = useState([]);
  const [expired, setExpired] = useState(false);

  const handleExpire = useCallback(() => {
    setExpired(true);
    if (typeof document !== 'undefined') {
      const offerCountdownTicker = document.querySelector('.ticker_wrapper');
      const tickerHeaderParent = document.querySelector('.ticker-header-parent');
      if (offerCountdownTicker) {
        offerCountdownTicker.classList.add('ticker_hidden');
      }
      if (tickerHeaderParent) {
        tickerHeaderParent.classList.remove('tickerOpen-parent');
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentTime = moment();

      const bannerCountdownItem = props?.ticker?.tickers?.Tickers?.targetItems?.find(
        (item) =>
          item.IsBannerCountdown?.value === '1' &&
          moment(item.OfferEndTime?.value, 'YYYYMMDDTHHmmssZ').isAfter(currentTime)
      );

      const filteredTickers = props?.ticker?.tickers?.Tickers?.targetItems?.filter(
        (item) =>
          (!item.IsTimer?.value && !item.IsBannerCountdown?.value) ||
          (item !== bannerCountdownItem &&
            moment(item.OfferEndTime?.value, 'YYYYMMDDTHHmmssZ').isAfter(currentTime))
      );

      setTickers(filteredTickers);

      if (bannerCountdownItem) {
        props.setBannerCountdownItem(bannerCountdownItem);
      }

      const tickerHeaderParent = document.querySelector('.ticker-header-parent');
      if (filteredTickers?.length === 0 && tickerHeaderParent) {
        tickerHeaderParent.classList.remove('tickerOpen-parent');
      }
    }
  }, [props]);

  useEffect(() => {
    const handleTickerStatus = () => {
      if (!tickers || tickers.length === 0) {
        setTicker(false);
        const tickerHeaderParent = document.querySelector('.ticker-header-parent');
        if (!ticker) {
          tickerHeaderParent.classList.remove('tickerOpen-parent');
        }
      }
    };
    handleTickerStatus();
  }, [tickers]);

  return (
    <>
      {tickers &&
        tickers?.map((ticker, index) => (
          <div
            key={index}
            className={`ticker_wrapper ${
              ticker.SectionCss?.value ? ticker.SectionCss?.value : ''
            } ${props?.menuOpen ? 'disclaimer_close' : ''}`}
          >
            <div className="ticker_container">
              <div className="ticker_row">
                <div className="ticker_content">
                  <RichText field={ticker?.Content} />
                </div>
                <div className="ticker_buy_button">
                  {ticker?.IsTimer?.value && (
                    <Countdown
                      endTime={moment(ticker.OfferEndTime?.value, 'YYYYMMDDTHHmmssZ').format(
                        'YYYY-MM-DDTHH:mm:ss'
                      )}
                      onExpire={handleExpire}
                    />
                  )}
                  {ticker?.CTAButton?.url && (
                    <a
                      className="buy_online_ticker"
                      href={ticker?.CTAButton?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {ticker?.CTAButton?.text}
                      {ticker?.CTAIcon?.src && <Image src={ticker?.Icon} />}
                    </a>
                  )}
                  {ticker?.IsForm?.value && (
                    <div className="newsletter_input_wrapper">
                      <div className="input_mb_mobile">
                        <input
                          type="text"
                          className="form-control required_field"
                          placeholder="Mobile Number"
                          aria-label="Username"
                          required=""
                          pattern="[0-9]{10}"
                        />
                        <button className="btn ticker_apply_now" type="button">
                          {props.t('ApplyNow')}
                        </button>
                        <span className="error-msg1" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {ticker?.Icon?.src && (
                <div className="ticker_close" onClick={handleExpire}>
                  <img src={ticker?.Icon?.src} alt="Ticker Close" />
                </div>
              )}
            </div>
          </div>
        ))}
    </>
  );
};

export default withTranslation()(React.memo(GraphQLData(TickerQuery, { name: 'ticker' })(Ticker)));
