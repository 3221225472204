import React from 'react';
import Loadable from 'react-loadable';
import { ComponentBuilder } from '@sitecore-jss/sitecore-jss-react';
const ABCDAppOnDesktopAndMoblie = Loadable({
    loader: () => import(/* webpackChunkName: "ABCDAppOnDesktopAndMoblie" */ '../components/ABCDAppOnDesktopAndMoblie/ABCDAppOnDesktopAndMoblie.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['ABCDAppOnDesktopAndMoblie/ABCDAppOnDesktopAndMoblie.js'],
  });
const AboutABCD = Loadable({
    loader: () => import(/* webpackChunkName: "AboutABCD" */ '../components/about-abcd/AboutABCD.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['about-abcd/AboutABCD.js'],
  });
const AccordianWithRightSideImage = Loadable({
    loader: () => import(/* webpackChunkName: "AccordianWithRightSideImage" */ '../components/accordian-with-right-side-Image/AccordianWithRightSideImage.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['accordian-with-right-side-Image/AccordianWithRightSideImage.js'],
  });
const AccordionBanner = Loadable({
    loader: () => import(/* webpackChunkName: "AccordionBanner" */ '../components/AccordionBanner/AccordionBanner.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['AccordionBanner/AccordionBanner.js'],
  });
const AdvantagesOfInvesting = Loadable({
    loader: () => import(/* webpackChunkName: "AdvantagesOfInvesting" */ '../components/AdvantagesOfInvesting/AdvantagesOfInvesting.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['AdvantagesOfInvesting/AdvantagesOfInvesting.js'],
  });
const ApplyLoan = Loadable({
    loader: () => import(/* webpackChunkName: "ApplyLoan" */ '../components/apply-loan/ApplyLoan.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['apply-loan/ApplyLoan.js'],
  });
const FullWidthBannerWithBenefits = Loadable({
    loader: () => import(/* webpackChunkName: "FullWidthBannerWithBenefits" */ '../components/banner/FullWidthBannerWithBenefits.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['banner/FullWidthBannerWithBenefits.js'],
  });
const FullWidthBannerWithVideo = Loadable({
    loader: () => import(/* webpackChunkName: "FullWidthBannerWithVideo" */ '../components/banner/FullWidthBannerWithVideo.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['banner/FullWidthBannerWithVideo.js'],
  });
const FullWidthBannerWithVisual = Loadable({
    loader: () => import(/* webpackChunkName: "FullWidthBannerWithVisual" */ '../components/banner/FullWidthBannerWithVisual.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['banner/FullWidthBannerWithVisual.js'],
  });
const JoinNowModal = Loadable({
    loader: () => import(/* webpackChunkName: "JoinNowModal" */ '../components/banner/JoinNowModal.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['banner/JoinNowModal.js'],
  });
const BlogCarouselLI = Loadable({
    loader: () => import(/* webpackChunkName: "BlogCarouselLI" */ '../components/BlogCarouselLi/BlogCarouselLI.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['BlogCarouselLi/BlogCarouselLI.js'],
  });
const BranchLocator = Loadable({
    loader: () => import(/* webpackChunkName: "BranchLocator" */ '../components/branchLocator/BranchLocator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['branchLocator/BranchLocator.js'],
  });
const Breadcrumbs = Loadable({
    loader: () => import(/* webpackChunkName: "Breadcrumbs" */ '../components/Breadcrumbs/Breadcrumbs.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Breadcrumbs/Breadcrumbs.js'],
  });
const ABCOfCalculatorsWrapper = Loadable({
    loader: () => import(/* webpackChunkName: "ABCOfCalculatorsWrapper" */ '../components/calculators/ABCOfCalculatorsWrapper/ABCOfCalculatorsWrapper.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/ABCOfCalculatorsWrapper/ABCOfCalculatorsWrapper.js'],
  });
const BLAmountCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "BLAmountCalculator" */ '../components/calculators/BLAmountCalculator/BLAmountCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/BLAmountCalculator/BLAmountCalculator.js'],
  });
const BLCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "BLCalculator" */ '../components/calculators/BLCalculator/BLCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/BLCalculator/BLCalculator.js'],
  });
const BLEMICalculator = Loadable({
    loader: () => import(/* webpackChunkName: "BLEMICalculator" */ '../components/calculators/BLEMICalculator/BLEMICalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/BLEMICalculator/BLEMICalculator.js'],
  });
const BTCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "BTCalculator" */ '../components/calculators/BTCalculator/BTCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/BTCalculator/BTCalculator.js'],
  });
const BTCalculatorInWrapper = Loadable({
    loader: () => import(/* webpackChunkName: "BTCalculatorInWrapper" */ '../components/calculators/BTCalculator_in_wrapper/BTCalculatorInWrapper.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/BTCalculator_in_wrapper/BTCalculatorInWrapper.js'],
  });
const BusinessLoanCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "BusinessLoanCalculator" */ '../components/calculators/BusinessLoanCalculator/BusinessLoanCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/BusinessLoanCalculator/BusinessLoanCalculator.js'],
  });
const CategoryCalculatorsWrapper = Loadable({
    loader: () => import(/* webpackChunkName: "CategoryCalculatorsWrapper" */ '../components/calculators/CategoryCalculatorsWrapper/CategoryCalculatorsWrapper.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/CategoryCalculatorsWrapper/CategoryCalculatorsWrapper.js'],
  });
const EligibilityCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "EligibilityCalculator" */ '../components/calculators/EligibilityCalculator/EligibilityCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/EligibilityCalculator/EligibilityCalculator.js'],
  });
const EMICalculator = Loadable({
    loader: () => import(/* webpackChunkName: "EMICalculator" */ '../components/calculators/EMICalculator/EMICalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/EMICalculator/EMICalculator.js'],
  });
const HLVCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "HLVCalculator" */ '../components/calculators/HLVCalculator/HLVCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/HLVCalculator/HLVCalculator.js'],
  });
const HRACalculator = Loadable({
    loader: () => import(/* webpackChunkName: "HRACalculator" */ '../components/calculators/HRACalculator/HRACalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/HRACalculator/HRACalculator.js'],
  });
const IncomeTaxCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "IncomeTaxCalculator" */ '../components/calculators/IncomeTaxCalculator/IncomeTaxCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/IncomeTaxCalculator/IncomeTaxCalculator.js'],
  });
const MFCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "MFCalculator" */ '../components/calculators/MFCalculator/MFCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/MFCalculator/MFCalculator.js'],
  });
const PMAYCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "PMAYCalculator" */ '../components/calculators/PMAY/PMAYCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/PMAY/PMAYCalculator.js'],
  });
const RentReceiptGenerator = Loadable({
    loader: () => import(/* webpackChunkName: "RentReceiptGenerator" */ '../components/calculators/RentReceiptGenerator/RentReceiptGenerator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/RentReceiptGenerator/RentReceiptGenerator.js'],
  });
const RentReceiptPDFGenerator = Loadable({
    loader: () => import(/* webpackChunkName: "RentReceiptPDFGenerator" */ '../components/calculators/RentReceiptGenerator/RentReceiptPDFGenerator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/RentReceiptGenerator/RentReceiptPDFGenerator.js'],
  });
const SIPCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "SIPCalculator" */ '../components/calculators/SIPCalculator/SIPCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['calculators/SIPCalculator/SIPCalculator.js'],
  });
const CardList = Loadable({
    loader: () => import(/* webpackChunkName: "CardList" */ '../components/CardList/CardList.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['CardList/CardList.js'],
  });
const CarouselSwiperFullWidth = Loadable({
    loader: () => import(/* webpackChunkName: "CarouselSwiperFullWidth" */ '../components/carousel/CarouselSwiperFullWidth.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['carousel/CarouselSwiperFullWidth.js'],
  });
const CategoryBanner = Loadable({
    loader: () => import(/* webpackChunkName: "CategoryBanner" */ '../components/category-banner/CategoryBanner.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['category-banner/CategoryBanner.js'],
  });
const BannerSlide = Loadable({
    loader: () => import(/* webpackChunkName: "BannerSlide" */ '../components/category-carousel/BannerSlide.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['category-carousel/BannerSlide.js'],
  });
const Carousel = Loadable({
    loader: () => import(/* webpackChunkName: "Carousel" */ '../components/category-carousel/Carousel.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['category-carousel/Carousel.js'],
  });
const CategoryCards = Loadable({
    loader: () => import(/* webpackChunkName: "CategoryCards" */ '../components/CategoryCards/CategoryCards.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['CategoryCards/CategoryCards.js'],
  });
const CategoryPaymentBanner = Loadable({
    loader: () => import(/* webpackChunkName: "CategoryPaymentBanner" */ '../components/CategoryPaymentBanner.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['CategoryPaymentBanner.js'],
  });
const CategorySummary = Loadable({
    loader: () => import(/* webpackChunkName: "CategorySummary" */ '../components/CategorySummary/CategorySummary.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['CategorySummary/CategorySummary.js'],
  });
const ABCDLeadForm = Loadable({
    loader: () => import(/* webpackChunkName: "ABCDLeadForm" */ '../components/Common/ABCDLeadForm.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/ABCDLeadForm.js'],
  });
const Card = Loadable({
    loader: () => import(/* webpackChunkName: "Card" */ '../components/Common/Card.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/Card.js'],
  });
const CardBranch = Loadable({
    loader: () => import(/* webpackChunkName: "CardBranch" */ '../components/Common/CardBranch.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/CardBranch.js'],
  });
const CardListItem = Loadable({
    loader: () => import(/* webpackChunkName: "CardListItem" */ '../components/Common/CardListItem.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/CardListItem.js'],
  });
const ConnectWithUsForm = Loadable({
    loader: () => import(/* webpackChunkName: "ConnectWithUsForm" */ '../components/Common/ConnectWithUsForm.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/ConnectWithUsForm.js'],
  });
const CountdownTimer = Loadable({
    loader: () => import(/* webpackChunkName: "CountdownTimer" */ '../components/Common/CountdownTimer.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/CountdownTimer.js'],
  });
const CountdownTimerSideMenu = Loadable({
    loader: () => import(/* webpackChunkName: "CountdownTimerSideMenu" */ '../components/Common/CountdownTimerSideMenu.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/CountdownTimerSideMenu.js'],
  });
const CustomLink = Loadable({
    loader: () => import(/* webpackChunkName: "CustomLink" */ '../components/Common/CustomLink.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/CustomLink.js'],
  });
const DynamicBranches = Loadable({
    loader: () => import(/* webpackChunkName: "DynamicBranches" */ '../components/Common/DynamicBranches.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/DynamicBranches.js'],
  });
const DynamicContent = Loadable({
    loader: () => import(/* webpackChunkName: "DynamicContent" */ '../components/Common/DynamicContent.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/DynamicContent.js'],
  });
const DynamicContent2 = Loadable({
    loader: () => import(/* webpackChunkName: "DynamicContent2" */ '../components/Common/DynamicContent2.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/DynamicContent2.js'],
  });
const DynamicContent3 = Loadable({
    loader: () => import(/* webpackChunkName: "DynamicContent3" */ '../components/Common/DynamicContent3.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/DynamicContent3.js'],
  });
const DynamicListItems = Loadable({
    loader: () => import(/* webpackChunkName: "DynamicListItems" */ '../components/Common/DynamicListItems.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/DynamicListItems.js'],
  });
const LeadForm = Loadable({
    loader: () => import(/* webpackChunkName: "LeadForm" */ '../components/Common/LeadForm.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/LeadForm.js'],
  });
const LeadFormButton = Loadable({
    loader: () => import(/* webpackChunkName: "LeadFormButton" */ '../components/Common/LeadFormButton.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/LeadFormButton.js'],
  });
const LinkProvision = Loadable({
    loader: () => import(/* webpackChunkName: "LinkProvision" */ '../components/Common/LinkProvision.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/LinkProvision.js'],
  });
const SimplifyTaxPlanningModal = Loadable({
    loader: () => import(/* webpackChunkName: "SimplifyTaxPlanningModal" */ '../components/Common/Modal/SimplifyTaxPlanningModal.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Common/Modal/SimplifyTaxPlanningModal.js'],
  });
const ComparisonTable = Loadable({
    loader: () => import(/* webpackChunkName: "ComparisonTable" */ '../components/comparison-table/ComparisonTable.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['comparison-table/ComparisonTable.js'],
  });
const ContentBlock = Loadable({
    loader: () => import(/* webpackChunkName: "ContentBlock" */ '../components/ContentBlock.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['ContentBlock.js'],
  });
const Disclaimer = Loadable({
    loader: () => import(/* webpackChunkName: "Disclaimer" */ '../components/Disclaimer/Disclaimer.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Disclaimer/Disclaimer.js'],
  });
const DownloadApp = Loadable({
    loader: () => import(/* webpackChunkName: "DownloadApp" */ '../components/downloadApp/DownloadApp.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['downloadApp/DownloadApp.js'],
  });
const IconCardsThreeColumnWithMiddleBanner = Loadable({
    loader: () => import(/* webpackChunkName: "IconCardsThreeColumnWithMiddleBanner" */ '../components/FactorsAffect/IconCardsThreeColumnWithMiddleBanner.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['FactorsAffect/IconCardsThreeColumnWithMiddleBanner.js'],
  });
const FAQs = Loadable({
    loader: () => import(/* webpackChunkName: "FAQs" */ '../components/faqs/FAQs.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['faqs/FAQs.js'],
  });
const FAQsWithSearch = Loadable({
    loader: () => import(/* webpackChunkName: "FAQsWithSearch" */ '../components/faqs with search/FAQsWithSearch.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['faqs with search/FAQsWithSearch.js'],
  });
const FDInvestmentCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "FDInvestmentCalculator" */ '../components/fd-investment-calculator/FDInvestmentCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['fd-investment-calculator/FDInvestmentCalculator.js'],
  });
const FeesnCharge = Loadable({
    loader: () => import(/* webpackChunkName: "FeesnCharge" */ '../components/FeesnCharge/FeesnCharge.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['FeesnCharge/FeesnCharge.js'],
  });
const StyleguideFieldUsageCheckbox = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideFieldUsageCheckbox" */ '../components/fields/Styleguide-FieldUsage-Checkbox.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['fields/Styleguide-FieldUsage-Checkbox.js'],
  });
const StyleguideFieldUsageContentList = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideFieldUsageContentList" */ '../components/fields/Styleguide-FieldUsage-ContentList.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['fields/Styleguide-FieldUsage-ContentList.js'],
  });
const StyleguideFieldUsageCustom = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideFieldUsageCustom" */ '../components/fields/Styleguide-FieldUsage-Custom.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['fields/Styleguide-FieldUsage-Custom.js'],
  });
const StyleguideFieldUsageDate = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideFieldUsageDate" */ '../components/fields/Styleguide-FieldUsage-Date.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['fields/Styleguide-FieldUsage-Date.js'],
  });
const StyleguideFieldUsageFile = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideFieldUsageFile" */ '../components/fields/Styleguide-FieldUsage-File.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['fields/Styleguide-FieldUsage-File.js'],
  });
const StyleguideFieldUsageImage = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideFieldUsageImage" */ '../components/fields/Styleguide-FieldUsage-Image.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['fields/Styleguide-FieldUsage-Image.js'],
  });
const StyleguideFieldUsageItemLink = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideFieldUsageItemLink" */ '../components/fields/Styleguide-FieldUsage-ItemLink.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['fields/Styleguide-FieldUsage-ItemLink.js'],
  });
const StyleguideFieldUsageLink = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideFieldUsageLink" */ '../components/fields/Styleguide-FieldUsage-Link.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['fields/Styleguide-FieldUsage-Link.js'],
  });
const StyleguideFieldUsageNumber = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideFieldUsageNumber" */ '../components/fields/Styleguide-FieldUsage-Number.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['fields/Styleguide-FieldUsage-Number.js'],
  });
const StyleguideFieldUsageRichText = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideFieldUsageRichText" */ '../components/fields/Styleguide-FieldUsage-RichText.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['fields/Styleguide-FieldUsage-RichText.js'],
  });
const StyleguideFieldUsageText = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideFieldUsageText" */ '../components/fields/Styleguide-FieldUsage-Text.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['fields/Styleguide-FieldUsage-Text.js'],
  });
const FixedBottomDrawer = Loadable({
    loader: () => import(/* webpackChunkName: "FixedBottomDrawer" */ '../components/FixedBottomDrawer/FixedBottomDrawer.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['FixedBottomDrawer/FixedBottomDrawer.js'],
  });
const FundDetails = Loadable({
    loader: () => import(/* webpackChunkName: "FundDetails" */ '../components/FundDetails/FundDetails.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['FundDetails/FundDetails.js'],
  });
const FundManagers = Loadable({
    loader: () => import(/* webpackChunkName: "FundManagers" */ '../components/FundManagers/FundManagers.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['FundManagers/FundManagers.js'],
  });
const FundOverview = Loadable({
    loader: () => import(/* webpackChunkName: "FundOverview" */ '../components/FundOverview/FundOverview.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['FundOverview/FundOverview.js'],
  });
const GetInsured = Loadable({
    loader: () => import(/* webpackChunkName: "GetInsured" */ '../components/get-insured/GetInsured.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['get-insured/GetInsured.js'],
  });
const GetHomeLoan = Loadable({
    loader: () => import(/* webpackChunkName: "GetHomeLoan" */ '../components/GetHomeLoan/GetHomeLoan.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['GetHomeLoan/GetHomeLoan.js'],
  });
const GoalTabs = Loadable({
    loader: () => import(/* webpackChunkName: "GoalTabs" */ '../components/GoalTabs/GoalTabs.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['GoalTabs/GoalTabs.js'],
  });
const GraphQLConnectedDemo = Loadable({
    loader: () => import(/* webpackChunkName: "GraphQLConnectedDemo" */ '../components/graphql/GraphQL-ConnectedDemo.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['graphql/GraphQL-ConnectedDemo.js'],
  });
const GraphQLIntegratedDemo = Loadable({
    loader: () => import(/* webpackChunkName: "GraphQLIntegratedDemo" */ '../components/graphql/GraphQL-IntegratedDemo.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['graphql/GraphQL-IntegratedDemo.js'],
  });
const GraphQLLayout = Loadable({
    loader: () => import(/* webpackChunkName: "GraphQLLayout" */ '../components/graphql/GraphQL-Layout.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['graphql/GraphQL-Layout.js'],
  });
const GrowWithABCD = Loadable({
    loader: () => import(/* webpackChunkName: "GrowWithABCD" */ '../components/GrowWithABCD/GrowWithABCD.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['GrowWithABCD/GrowWithABCD.js'],
  });
const HomeLoanCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "HomeLoanCalculator" */ '../components/HomeLoanCalculator/HomeLoanCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['HomeLoanCalculator/HomeLoanCalculator.js'],
  });
const DownloadAppModal = Loadable({
    loader: () => import(/* webpackChunkName: "DownloadAppModal" */ '../components/HomepageAppHero/DownloadAppModal.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['HomepageAppHero/DownloadAppModal.js'],
  });
const HomepageAppHero = Loadable({
    loader: () => import(/* webpackChunkName: "HomepageAppHero" */ '../components/HomepageAppHero/HomepageAppHero.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['HomepageAppHero/HomepageAppHero.js'],
  });
const HorizontalAccordianCards = Loadable({
    loader: () => import(/* webpackChunkName: "HorizontalAccordianCards" */ '../components/HorizontalAccordianCards/HorizontalAccordianCards.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['HorizontalAccordianCards/HorizontalAccordianCards.js'],
  });
const HorizontalCardsSlider = Loadable({
    loader: () => import(/* webpackChunkName: "HorizontalCardsSlider" */ '../components/HorizontalCardsSlider/HorizontalCardsSlider.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['HorizontalCardsSlider/HorizontalCardsSlider.js'],
  });
const HowToPayOnCall = Loadable({
    loader: () => import(/* webpackChunkName: "HowToPayOnCall" */ '../components/HowToPayOnCall.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['HowToPayOnCall.js'],
  });
const InsuranceComparison = Loadable({
    loader: () => import(/* webpackChunkName: "InsuranceComparison" */ '../components/insurance-comparison/InsuranceComparison.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['insurance-comparison/InsuranceComparison.js'],
  });
const InterestRate = Loadable({
    loader: () => import(/* webpackChunkName: "InterestRate" */ '../components/interest-rate/InterestRate.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['interest-rate/InterestRate.js'],
  });
const InvestByCategory = Loadable({
    loader: () => import(/* webpackChunkName: "InvestByCategory" */ '../components/InvestByCategory/InvestByCategory.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['InvestByCategory/InvestByCategory.js'],
  });
const IPOTabbedListing = Loadable({
    loader: () => import(/* webpackChunkName: "IPOTabbedListing" */ '../components/IPOTabbedListing/IPOTabbedListing.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['IPOTabbedListing/IPOTabbedListing.js'],
  });
const EnquireModal = Loadable({
    loader: () => import(/* webpackChunkName: "EnquireModal" */ '../components/life-insurance-plans/EnquireModal.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['life-insurance-plans/EnquireModal.js'],
  });
const LifeInsurancePlanCard = Loadable({
    loader: () => import(/* webpackChunkName: "LifeInsurancePlanCard" */ '../components/life-insurance-plans/LifeInsurancePlanCard.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['life-insurance-plans/LifeInsurancePlanCard.js'],
  });
const LifeInsurancePlans = Loadable({
    loader: () => import(/* webpackChunkName: "LifeInsurancePlans" */ '../components/life-insurance-plans/LifeInsurancePlans.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['life-insurance-plans/LifeInsurancePlans.js'],
  });
const LifeinsuranceCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "LifeinsuranceCalculator" */ '../components/lifeinsurance-calculator/LifeinsuranceCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['lifeinsurance-calculator/LifeinsuranceCalculator.js'],
  });
const Loader = Loadable({
    loader: () => import(/* webpackChunkName: "Loader" */ '../components/Loader/Loader.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Loader/Loader.js'],
  });
const LoanEligibility = Loadable({
    loader: () => import(/* webpackChunkName: "LoanEligibility" */ '../components/LoanEligibility/LoanEligibility.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['LoanEligibility/LoanEligibility.js'],
  });
const ManageProductCredit = Loadable({
    loader: () => import(/* webpackChunkName: "ManageProductCredit" */ '../components/ManageProductCredit/ManageProductCredit.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['ManageProductCredit/ManageProductCredit.js'],
  });
const MarketIndices = Loadable({
    loader: () => import(/* webpackChunkName: "MarketIndices" */ '../components/market-indices/MarketIndices.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['market-indices/MarketIndices.js'],
  });
const MarketToday = Loadable({
    loader: () => import(/* webpackChunkName: "MarketToday" */ '../components/MarketToday/MarketToday.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['MarketToday/MarketToday.js'],
  });
const MFSwiper = Loadable({
    loader: () => import(/* webpackChunkName: "MFSwiper" */ '../components/MFSwiper/MFSwiper.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['MFSwiper/MFSwiper.js'],
  });
const ABCDFooter = Loadable({
    loader: () => import(/* webpackChunkName: "ABCDFooter" */ '../components/navigation/ABCDFooter.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/ABCDFooter.js'],
  });
const ABCDFooterVariant = Loadable({
    loader: () => import(/* webpackChunkName: "ABCDFooterVariant" */ '../components/navigation/ABCDFooterVariant.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/ABCDFooterVariant.js'],
  });
const DisclaimerMessage = Loadable({
    loader: () => import(/* webpackChunkName: "DisclaimerMessage" */ '../components/navigation/DisclaimerMessage.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/DisclaimerMessage.js'],
  });
const FinancialSolutions = Loadable({
    loader: () => import(/* webpackChunkName: "FinancialSolutions" */ '../components/navigation/FinancialSolutions.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/FinancialSolutions.js'],
  });
const Footer = Loadable({
    loader: () => import(/* webpackChunkName: "Footer" */ '../components/navigation/Footer.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/Footer.js'],
  });
const Header = Loadable({
    loader: () => import(/* webpackChunkName: "Header" */ '../components/navigation/Header.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/Header.js'],
  });
const MegaMenuHolder = Loadable({
    loader: () => import(/* webpackChunkName: "MegaMenuHolder" */ '../components/navigation/MegaMenuHolder.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/MegaMenuHolder.js'],
  });
const MegaMenuQuickServices = Loadable({
    loader: () => import(/* webpackChunkName: "MegaMenuQuickServices" */ '../components/navigation/MegaMenuQuickServices.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/MegaMenuQuickServices.js'],
  });
const MobileFinancialSolutions = Loadable({
    loader: () => import(/* webpackChunkName: "MobileFinancialSolutions" */ '../components/navigation/MobileFinancialSolutions.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/MobileFinancialSolutions.js'],
  });
const MobileNavigation = Loadable({
    loader: () => import(/* webpackChunkName: "MobileNavigation" */ '../components/navigation/MobileNavigation.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/MobileNavigation.js'],
  });
const NavigationCrossSell = Loadable({
    loader: () => import(/* webpackChunkName: "NavigationCrossSell" */ '../components/navigation/NavigationCrossSell.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/NavigationCrossSell.js'],
  });
const SearchMenu = Loadable({
    loader: () => import(/* webpackChunkName: "SearchMenu" */ '../components/navigation/SearchMenu.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/SearchMenu.js'],
  });
const Tools = Loadable({
    loader: () => import(/* webpackChunkName: "Tools" */ '../components/navigation/Tools.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/Tools.js'],
  });
const TopNavigation = Loadable({
    loader: () => import(/* webpackChunkName: "TopNavigation" */ '../components/navigation/TopNavigation.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['navigation/TopNavigation.js'],
  });
const OurOfferings = Loadable({
    loader: () => import(/* webpackChunkName: "OurOfferings" */ '../components/our-offerings/OurOfferings.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['our-offerings/OurOfferings.js'],
  });
const OurSavingsPlans = Loadable({
    loader: () => import(/* webpackChunkName: "OurSavingsPlans" */ '../components/our-savings-plans/OurSavingsPlans.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['our-savings-plans/OurSavingsPlans.js'],
  });
const PartnerWith = Loadable({
    loader: () => import(/* webpackChunkName: "PartnerWith" */ '../components/partner-with/PartnerWith.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['partner-with/PartnerWith.js'],
  });
const PayAnyBill = Loadable({
    loader: () => import(/* webpackChunkName: "PayAnyBill" */ '../components/PayAnyBill.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['PayAnyBill.js'],
  });
const PayAnyone = Loadable({
    loader: () => import(/* webpackChunkName: "PayAnyone" */ '../components/PayAnyone/PayAnyone.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['PayAnyone/PayAnyone.js'],
  });
const PlainCardWithBackground = Loadable({
    loader: () => import(/* webpackChunkName: "PlainCardWithBackground" */ '../components/plain-cards-with-background/PlainCardWithBackground.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['plain-cards-with-background/PlainCardWithBackground.js'],
  });
const PlansOverviewBlock = Loadable({
    loader: () => import(/* webpackChunkName: "PlansOverviewBlock" */ '../components/PlansOverviewBlock/PlansOverviewBlock.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['PlansOverviewBlock/PlansOverviewBlock.js'],
  });
const Portfolio = Loadable({
    loader: () => import(/* webpackChunkName: "Portfolio" */ '../components/Portfolio/Portfolio.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Portfolio/Portfolio.js'],
  });
const ABSLIFortuneElitePlan = Loadable({
    loader: () => import(/* webpackChunkName: "ABSLIFortuneElitePlan" */ '../components/product-page/ABSLIFortuneElitePlan/ABSLIFortuneElitePlan.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['product-page/ABSLIFortuneElitePlan/ABSLIFortuneElitePlan.js'],
  });
const RidersExtraProtection = Loadable({
    loader: () => import(/* webpackChunkName: "RidersExtraProtection" */ '../components/product-page/add-riders-for-extra-protection/RidersExtraProtection.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['product-page/add-riders-for-extra-protection/RidersExtraProtection.js'],
  });
const BestsellignProducts = Loadable({
    loader: () => import(/* webpackChunkName: "BestsellignProducts" */ '../components/product-page/BestsellignProducts/BestsellignProducts.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['product-page/BestsellignProducts/BestsellignProducts.js'],
  });
const ChooseRightOption = Loadable({
    loader: () => import(/* webpackChunkName: "ChooseRightOption" */ '../components/product-page/ChooseRightOption/ChooseRightOption.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['product-page/ChooseRightOption/ChooseRightOption.js'],
  });
const PlanCard = Loadable({
    loader: () => import(/* webpackChunkName: "PlanCard" */ '../components/product-page/ChooseRightOption/PlanCard.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['product-page/ChooseRightOption/PlanCard.js'],
  });
const GetStartedABSLIFortuneElitePlan = Loadable({
    loader: () => import(/* webpackChunkName: "GetStartedABSLIFortuneElitePlan" */ '../components/product-page/get-started-fortune-elite-plan/GetStartedABSLIFortuneElitePlan.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['product-page/get-started-fortune-elite-plan/GetStartedABSLIFortuneElitePlan.js'],
  });
const OtherPlanCard = Loadable({
    loader: () => import(/* webpackChunkName: "OtherPlanCard" */ '../components/product-page/other-plans/OtherPlanCard.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['product-page/other-plans/OtherPlanCard.js'],
  });
const OtherPlans = Loadable({
    loader: () => import(/* webpackChunkName: "OtherPlans" */ '../components/product-page/other-plans/OtherPlans.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['product-page/other-plans/OtherPlans.js'],
  });
const ProductPolicy = Loadable({
    loader: () => import(/* webpackChunkName: "ProductPolicy" */ '../components/product-page/what-included/ProductPolicy.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['product-page/what-included/ProductPolicy.js'],
  });
const ProductPurpose = Loadable({
    loader: () => import(/* webpackChunkName: "ProductPurpose" */ '../components/product-purpose/ProductPurpose.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['product-purpose/ProductPurpose.js'],
  });
const ReasonsToBuy = Loadable({
    loader: () => import(/* webpackChunkName: "ReasonsToBuy" */ '../components/ReasonsToBuy/ReasonsToBuy.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['ReasonsToBuy/ReasonsToBuy.js'],
  });
const RequiredDocuments = Loadable({
    loader: () => import(/* webpackChunkName: "RequiredDocuments" */ '../components/RequiredDocuments/RequiredDocuments.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['RequiredDocuments/RequiredDocuments.js'],
  });
const Riskometer = Loadable({
    loader: () => import(/* webpackChunkName: "Riskometer" */ '../components/Riskometer/Riskometer.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Riskometer/Riskometer.js'],
  });
const CheckEligibility = Loadable({
    loader: () => import(/* webpackChunkName: "CheckEligibility" */ '../components/savings-plan/CheckEligibility/CheckEligibility.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['savings-plan/CheckEligibility/CheckEligibility.js'],
  });
const AudioSlide = Loadable({
    loader: () => import(/* webpackChunkName: "AudioSlide" */ '../components/savings-plan/ExpertsOpinion/AudioSlide.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['savings-plan/ExpertsOpinion/AudioSlide.js'],
  });
const ExpertsOpinion = Loadable({
    loader: () => import(/* webpackChunkName: "ExpertsOpinion" */ '../components/savings-plan/ExpertsOpinion/ExpertsOpinion.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['savings-plan/ExpertsOpinion/ExpertsOpinion.js'],
  });
const TextSlide = Loadable({
    loader: () => import(/* webpackChunkName: "TextSlide" */ '../components/savings-plan/ExpertsOpinion/TextSlide.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['savings-plan/ExpertsOpinion/TextSlide.js'],
  });
const VideoSlide = Loadable({
    loader: () => import(/* webpackChunkName: "VideoSlide" */ '../components/savings-plan/ExpertsOpinion/VideoSlide.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['savings-plan/ExpertsOpinion/VideoSlide.js'],
  });
const SavingPlanCalculator = Loadable({
    loader: () => import(/* webpackChunkName: "SavingPlanCalculator" */ '../components/savings-plan/SavingPlanCalculator/SavingPlanCalculator.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['savings-plan/SavingPlanCalculator/SavingPlanCalculator.js'],
  });
const ScrollTop = Loadable({
    loader: () => import(/* webpackChunkName: "ScrollTop" */ '../components/scroll-top/ScrollTop.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['scroll-top/ScrollTop.js'],
  });
const SectorHoldings = Loadable({
    loader: () => import(/* webpackChunkName: "SectorHoldings" */ '../components/SectorHoldings/SectorHoldings.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['SectorHoldings/SectorHoldings.js'],
  });
const SideMenuGetInTouch = Loadable({
    loader: () => import(/* webpackChunkName: "SideMenuGetInTouch" */ '../components/side-menu/SideMenu-GetInTouch.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['side-menu/SideMenu-GetInTouch.js'],
  });
const SideMenuOurCompanies = Loadable({
    loader: () => import(/* webpackChunkName: "SideMenuOurCompanies" */ '../components/side-menu/SideMenu-OurCompanies.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['side-menu/SideMenu-OurCompanies.js'],
  });
const SideMenu = Loadable({
    loader: () => import(/* webpackChunkName: "SideMenu" */ '../components/side-menu/SideMenu.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['side-menu/SideMenu.js'],
  });
const SideMenuDownloadApp = Loadable({
    loader: () => import(/* webpackChunkName: "SideMenuDownloadApp" */ '../components/side-menu/SideMenuDownloadApp.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['side-menu/SideMenuDownloadApp.js'],
  });
const CalculateSIPLeadForm = Loadable({
    loader: () => import(/* webpackChunkName: "CalculateSIPLeadForm" */ '../components/SideModals/CalculateSIPLeadForm/CalculateSIPLeadForm.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['SideModals/CalculateSIPLeadForm/CalculateSIPLeadForm.js'],
  });
const EligibilityLeadForm = Loadable({
    loader: () => import(/* webpackChunkName: "EligibilityLeadForm" */ '../components/SideModals/EligibilityLeadForm/EligibilityLeadForm.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['SideModals/EligibilityLeadForm/EligibilityLeadForm.js'],
  });
const SignatureClub = Loadable({
    loader: () => import(/* webpackChunkName: "SignatureClub" */ '../components/signautre-club/SignatureClub.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['signautre-club/SignatureClub.js'],
  });
const SimplifyTaxPlanning = Loadable({
    loader: () => import(/* webpackChunkName: "SimplifyTaxPlanning" */ '../components/simplify-tax-planning/SimplifyTaxPlanning.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['simplify-tax-planning/SimplifyTaxPlanning.js'],
  });
const SolutionsCardsListing = Loadable({
    loader: () => import(/* webpackChunkName: "SolutionsCardsListing" */ '../components/SolutionsCardsListing/SolutionsCardsListing.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['SolutionsCardsListing/SolutionsCardsListing.js'],
  });
const StartInvesting = Loadable({
    loader: () => import(/* webpackChunkName: "StartInvesting" */ '../components/start-investing/StartInvesting.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['start-investing/StartInvesting.js'],
  });
const StartJourneyForm = Loadable({
    loader: () => import(/* webpackChunkName: "StartJourneyForm" */ '../components/start-journey-form/StartJourneyForm.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['start-journey-form/StartJourneyForm.js'],
  });
const StickyForm = Loadable({
    loader: () => import(/* webpackChunkName: "StickyForm" */ '../components/sticky-form/StickyForm.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['sticky-form/StickyForm.js'],
  });
const StickyNavigation = Loadable({
    loader: () => import(/* webpackChunkName: "StickyNavigation" */ '../components/StickyNavigation/StickyNavigation.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['StickyNavigation/StickyNavigation.js'],
  });
const StocksCards = Loadable({
    loader: () => import(/* webpackChunkName: "StocksCards" */ '../components/stocks-cards/StocksCards.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['stocks-cards/StocksCards.js'],
  });
const StocksFilter = Loadable({
    loader: () => import(/* webpackChunkName: "StocksFilter" */ '../components/StocksFilter/StocksFilter.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['StocksFilter/StocksFilter.js'],
  });
const StyleguideComponentParams = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideComponentParams" */ '../components/styleguide/Styleguide-ComponentParams.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['styleguide/Styleguide-ComponentParams.js'],
  });
const StyleguideCustomRouteType = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideCustomRouteType" */ '../components/styleguide/Styleguide-CustomRouteType.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['styleguide/Styleguide-CustomRouteType.js'],
  });
const StyleguideEditFrame = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideEditFrame" */ '../components/styleguide/Styleguide-EditFrame.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['styleguide/Styleguide-EditFrame.js'],
  });
const StyleguideLayoutReuse = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideLayoutReuse" */ '../components/styleguide/Styleguide-Layout-Reuse.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['styleguide/Styleguide-Layout-Reuse.js'],
  });
const StyleguideLayoutTabsTab = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideLayoutTabsTab" */ '../components/styleguide/Styleguide-Layout-Tabs-Tab.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['styleguide/Styleguide-Layout-Tabs-Tab.js'],
  });
const StyleguideLayoutTabs = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideLayoutTabs" */ '../components/styleguide/Styleguide-Layout-Tabs.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['styleguide/Styleguide-Layout-Tabs.js'],
  });
const StyleguideLayout = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideLayout" */ '../components/styleguide/Styleguide-Layout.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['styleguide/Styleguide-Layout.js'],
  });
const StyleguideMultilingual = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideMultilingual" */ '../components/styleguide/Styleguide-Multilingual.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['styleguide/Styleguide-Multilingual.js'],
  });
const StyleguideRouteFields = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideRouteFields" */ '../components/styleguide/Styleguide-RouteFields.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['styleguide/Styleguide-RouteFields.js'],
  });
const StyleguideSection = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideSection" */ '../components/styleguide/Styleguide-Section.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['styleguide/Styleguide-Section.js'],
  });
const StyleguideSitecoreContext = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideSitecoreContext" */ '../components/styleguide/Styleguide-SitecoreContext.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['styleguide/Styleguide-SitecoreContext.js'],
  });
const StyleguideSpecimen = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideSpecimen" */ '../components/styleguide/Styleguide-Specimen.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['styleguide/Styleguide-Specimen.js'],
  });
const StyleguideTracking = Loadable({
    loader: () => import(/* webpackChunkName: "StyleguideTracking" */ '../components/styleguide/Styleguide-Tracking.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['styleguide/Styleguide-Tracking.js'],
  });
const SubcriptionPlans = Loadable({
    loader: () => import(/* webpackChunkName: "SubcriptionPlans" */ '../components/SubcriptionPlans/SubcriptionPlans.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['SubcriptionPlans/SubcriptionPlans.js'],
  });
const TabbedProductWork = Loadable({
    loader: () => import(/* webpackChunkName: "TabbedProductWork" */ '../components/Tabbed-Product-Work/TabbedProductWork.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Tabbed-Product-Work/TabbedProductWork.js'],
  });
const TabbedBlogCarousal = Loadable({
    loader: () => import(/* webpackChunkName: "TabbedBlogCarousal" */ '../components/TabbedBlogCarousal/TabbedBlogCarousal.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['TabbedBlogCarousal/TabbedBlogCarousal.js'],
  });
const TabbedFAQ = Loadable({
    loader: () => import(/* webpackChunkName: "TabbedFAQ" */ '../components/TabbedFAQ/TabbedFAQ.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['TabbedFAQ/TabbedFAQ.js'],
  });
const TabbedProductCategoryListing = Loadable({
    loader: () => import(/* webpackChunkName: "TabbedProductCategoryListing" */ '../components/TabbedProductCategoryListing/TabbedProductCategoryListing.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['TabbedProductCategoryListing/TabbedProductCategoryListing.js'],
  });
const Testimonials = Loadable({
    loader: () => import(/* webpackChunkName: "Testimonials" */ '../components/Testimonials/Testimonials.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Testimonials/Testimonials.js'],
  });
const TestimonialSlider = Loadable({
    loader: () => import(/* webpackChunkName: "TestimonialSlider" */ '../components/TestimonialSlider.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['TestimonialSlider.js'],
  });
const ThingsToKeepInMind = Loadable({
    loader: () => import(/* webpackChunkName: "ThingsToKeepInMind" */ '../components/things-to-keep-in-mind/ThingsToKeepInMind.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['things-to-keep-in-mind/ThingsToKeepInMind.js'],
  });
const reactNouislider = Loadable({
    loader: () => import(/* webpackChunkName: "reactNouislider" */ '../components/third-party/react-nouislider.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['third-party/react-nouislider.js'],
  });
const ThreeCardsBanner = Loadable({
    loader: () => import(/* webpackChunkName: "ThreeCardsBanner" */ '../components/ThreeCardsBanner/ThreeCardsBanner.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['ThreeCardsBanner/ThreeCardsBanner.js'],
  });
const BottomTicker = Loadable({
    loader: () => import(/* webpackChunkName: "BottomTicker" */ '../components/ticker/BottomTicker.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['ticker/BottomTicker.js'],
  });
const Countdown = Loadable({
    loader: () => import(/* webpackChunkName: "Countdown" */ '../components/ticker/Countdown.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['ticker/Countdown.js'],
  });
const Ticker = Loadable({
    loader: () => import(/* webpackChunkName: "Ticker" */ '../components/ticker/Ticker.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['ticker/Ticker.js'],
  });
const TimeLineSteps = Loadable({
    loader: () => import(/* webpackChunkName: "TimeLineSteps" */ '../components/timeline-steps/TimeLineSteps.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['timeline-steps/TimeLineSteps.js'],
  });
const HeadingSubHeadingWithBackgroundImage = Loadable({
    loader: () => import(/* webpackChunkName: "HeadingSubHeadingWithBackgroundImage" */ '../components/timeline-steps copy/HeadingSubHeadingWithBackgroundImage.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['timeline-steps copy/HeadingSubHeadingWithBackgroundImage.js'],
  });
const TrendingStocksTable = Loadable({
    loader: () => import(/* webpackChunkName: "TrendingStocksTable" */ '../components/trending-stocks-table/TrendingStocksTable.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['trending-stocks-table/TrendingStocksTable.js'],
  });
const TrustBanner = Loadable({
    loader: () => import(/* webpackChunkName: "TrustBanner" */ '../components/TrustBanner/TrustBanner.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['TrustBanner/TrustBanner.js'],
  });
const UnderstandLifeInsurance = Loadable({
    loader: () => import(/* webpackChunkName: "UnderstandLifeInsurance" */ '../components/understand-life-insurance/UnderstandLifeInsurance.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['understand-life-insurance/UnderstandLifeInsurance.js'],
  });
const UPI123Section = Loadable({
    loader: () => import(/* webpackChunkName: "UPI123Section" */ '../components/upi-123-section/UPI123Section.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['upi-123-section/UPI123Section.js'],
  });
const WhyLifeInsurance = Loadable({
    loader: () => import(/* webpackChunkName: "WhyLifeInsurance" */ '../components/why-life-insurance/WhyLifeInsurance.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['why-life-insurance/WhyLifeInsurance.js'],
  });
const YellowCardsSlider = Loadable({
    loader: () => import(/* webpackChunkName: "YellowCardsSlider" */ '../components/Yellow-Cards-Slider/YellowCardsSlider.js'),
    // loading: () => <div>Loading...</div>,
    loading: () => <div></div>,
    modules: ['Yellow-Cards-Slider/YellowCardsSlider.js'],
  });

const components = new Map();
components.set('ABCDAppOnDesktopAndMoblie', ABCDAppOnDesktopAndMoblie);
components.set('AboutABCD', AboutABCD);
components.set('AccordianWithRightSideImage', AccordianWithRightSideImage);
components.set('AccordionBanner', AccordionBanner);
components.set('AdvantagesOfInvesting', AdvantagesOfInvesting);
components.set('ApplyLoan', ApplyLoan);
components.set('FullWidthBannerWithBenefits', FullWidthBannerWithBenefits);
components.set('FullWidthBannerWithVideo', FullWidthBannerWithVideo);
components.set('FullWidthBannerWithVisual', FullWidthBannerWithVisual);
components.set('JoinNowModal', JoinNowModal);
components.set('BlogCarouselLI', BlogCarouselLI);
components.set('BranchLocator', BranchLocator);
components.set('Breadcrumbs', Breadcrumbs);
components.set('ABCOfCalculatorsWrapper', ABCOfCalculatorsWrapper);
components.set('BLAmountCalculator', BLAmountCalculator);
components.set('BLCalculator', BLCalculator);
components.set('BLEMICalculator', BLEMICalculator);
components.set('BTCalculator', BTCalculator);
components.set('BTCalculatorInWrapper', BTCalculatorInWrapper);
components.set('BusinessLoanCalculator', BusinessLoanCalculator);
components.set('CategoryCalculatorsWrapper', CategoryCalculatorsWrapper);
components.set('EligibilityCalculator', EligibilityCalculator);
components.set('EMICalculator', EMICalculator);
components.set('HLVCalculator', HLVCalculator);
components.set('HRACalculator', HRACalculator);
components.set('IncomeTaxCalculator', IncomeTaxCalculator);
components.set('MFCalculator', MFCalculator);
components.set('PMAYCalculator', PMAYCalculator);
components.set('RentReceiptGenerator', RentReceiptGenerator);
components.set('RentReceiptPDFGenerator', RentReceiptPDFGenerator);
components.set('SIPCalculator', SIPCalculator);
components.set('CardList', CardList);
components.set('CarouselSwiperFullWidth', CarouselSwiperFullWidth);
components.set('CategoryBanner', CategoryBanner);
components.set('BannerSlide', BannerSlide);
components.set('Carousel', Carousel);
components.set('CategoryCards', CategoryCards);
components.set('CategoryPaymentBanner', CategoryPaymentBanner);
components.set('CategorySummary', CategorySummary);
components.set('ABCDLeadForm', ABCDLeadForm);
components.set('Card', Card);
components.set('CardBranch', CardBranch);
components.set('CardListItem', CardListItem);
components.set('ConnectWithUsForm', ConnectWithUsForm);
components.set('CountdownTimer', CountdownTimer);
components.set('CountdownTimerSideMenu', CountdownTimerSideMenu);
components.set('CustomLink', CustomLink);
components.set('DynamicBranches', DynamicBranches);
components.set('DynamicContent', DynamicContent);
components.set('DynamicContent2', DynamicContent2);
components.set('DynamicContent3', DynamicContent3);
components.set('DynamicListItems', DynamicListItems);
components.set('LeadForm', LeadForm);
components.set('LeadFormButton', LeadFormButton);
components.set('LinkProvision', LinkProvision);
components.set('SimplifyTaxPlanningModal', SimplifyTaxPlanningModal);
components.set('ComparisonTable', ComparisonTable);
components.set('ContentBlock', ContentBlock);
components.set('Disclaimer', Disclaimer);
components.set('DownloadApp', DownloadApp);
components.set('IconCardsThreeColumnWithMiddleBanner', IconCardsThreeColumnWithMiddleBanner);
components.set('FAQs', FAQs);
components.set('FAQsWithSearch', FAQsWithSearch);
components.set('FDInvestmentCalculator', FDInvestmentCalculator);
components.set('FeesnCharge', FeesnCharge);
components.set('Styleguide-FieldUsage-Checkbox', StyleguideFieldUsageCheckbox);
components.set('Styleguide-FieldUsage-ContentList', StyleguideFieldUsageContentList);
components.set('Styleguide-FieldUsage-Custom', StyleguideFieldUsageCustom);
components.set('Styleguide-FieldUsage-Date', StyleguideFieldUsageDate);
components.set('Styleguide-FieldUsage-File', StyleguideFieldUsageFile);
components.set('Styleguide-FieldUsage-Image', StyleguideFieldUsageImage);
components.set('Styleguide-FieldUsage-ItemLink', StyleguideFieldUsageItemLink);
components.set('Styleguide-FieldUsage-Link', StyleguideFieldUsageLink);
components.set('Styleguide-FieldUsage-Number', StyleguideFieldUsageNumber);
components.set('Styleguide-FieldUsage-RichText', StyleguideFieldUsageRichText);
components.set('Styleguide-FieldUsage-Text', StyleguideFieldUsageText);
components.set('FixedBottomDrawer', FixedBottomDrawer);
components.set('FundDetails', FundDetails);
components.set('FundManagers', FundManagers);
components.set('FundOverview', FundOverview);
components.set('GetInsured', GetInsured);
components.set('GetHomeLoan', GetHomeLoan);
components.set('GoalTabs', GoalTabs);
components.set('GraphQL-ConnectedDemo', GraphQLConnectedDemo);
components.set('GraphQL-IntegratedDemo', GraphQLIntegratedDemo);
components.set('GraphQL-Layout', GraphQLLayout);
components.set('GrowWithABCD', GrowWithABCD);
components.set('HomeLoanCalculator', HomeLoanCalculator);
components.set('DownloadAppModal', DownloadAppModal);
components.set('HomepageAppHero', HomepageAppHero);
components.set('HorizontalAccordianCards', HorizontalAccordianCards);
components.set('HorizontalCardsSlider', HorizontalCardsSlider);
components.set('HowToPayOnCall', HowToPayOnCall);
components.set('InsuranceComparison', InsuranceComparison);
components.set('InterestRate', InterestRate);
components.set('InvestByCategory', InvestByCategory);
components.set('IPOTabbedListing', IPOTabbedListing);
components.set('EnquireModal', EnquireModal);
components.set('LifeInsurancePlanCard', LifeInsurancePlanCard);
components.set('LifeInsurancePlans', LifeInsurancePlans);
components.set('LifeinsuranceCalculator', LifeinsuranceCalculator);
components.set('Loader', Loader);
components.set('LoanEligibility', LoanEligibility);
components.set('ManageProductCredit', ManageProductCredit);
components.set('MarketIndices', MarketIndices);
components.set('MarketToday', MarketToday);
components.set('MFSwiper', MFSwiper);
components.set('ABCDFooter', ABCDFooter);
components.set('ABCDFooterVariant', ABCDFooterVariant);
components.set('DisclaimerMessage', DisclaimerMessage);
components.set('FinancialSolutions', FinancialSolutions);
components.set('Footer', Footer);
components.set('Header', Header);
components.set('MegaMenuHolder', MegaMenuHolder);
components.set('MegaMenuQuickServices', MegaMenuQuickServices);
components.set('MobileFinancialSolutions', MobileFinancialSolutions);
components.set('MobileNavigation', MobileNavigation);
components.set('NavigationCrossSell', NavigationCrossSell);
components.set('SearchMenu', SearchMenu);
components.set('Tools', Tools);
components.set('TopNavigation', TopNavigation);
components.set('OurOfferings', OurOfferings);
components.set('OurSavingsPlans', OurSavingsPlans);
components.set('PartnerWith', PartnerWith);
components.set('PayAnyBill', PayAnyBill);
components.set('PayAnyone', PayAnyone);
components.set('PlainCardWithBackground', PlainCardWithBackground);
components.set('PlansOverviewBlock', PlansOverviewBlock);
components.set('Portfolio', Portfolio);
components.set('ABSLIFortuneElitePlan', ABSLIFortuneElitePlan);
components.set('RidersExtraProtection', RidersExtraProtection);
components.set('BestsellignProducts', BestsellignProducts);
components.set('ChooseRightOption', ChooseRightOption);
components.set('PlanCard', PlanCard);
components.set('GetStartedABSLIFortuneElitePlan', GetStartedABSLIFortuneElitePlan);
components.set('OtherPlanCard', OtherPlanCard);
components.set('OtherPlans', OtherPlans);
components.set('ProductPolicy', ProductPolicy);
components.set('ProductPurpose', ProductPurpose);
components.set('ReasonsToBuy', ReasonsToBuy);
components.set('RequiredDocuments', RequiredDocuments);
components.set('Riskometer', Riskometer);
components.set('CheckEligibility', CheckEligibility);
components.set('AudioSlide', AudioSlide);
components.set('ExpertsOpinion', ExpertsOpinion);
components.set('TextSlide', TextSlide);
components.set('VideoSlide', VideoSlide);
components.set('SavingPlanCalculator', SavingPlanCalculator);
components.set('ScrollTop', ScrollTop);
components.set('SectorHoldings', SectorHoldings);
components.set('SideMenu-GetInTouch', SideMenuGetInTouch);
components.set('SideMenu-OurCompanies', SideMenuOurCompanies);
components.set('SideMenu', SideMenu);
components.set('SideMenuDownloadApp', SideMenuDownloadApp);
components.set('CalculateSIPLeadForm', CalculateSIPLeadForm);
components.set('EligibilityLeadForm', EligibilityLeadForm);
components.set('SignatureClub', SignatureClub);
components.set('SimplifyTaxPlanning', SimplifyTaxPlanning);
components.set('SolutionsCardsListing', SolutionsCardsListing);
components.set('StartInvesting', StartInvesting);
components.set('StartJourneyForm', StartJourneyForm);
components.set('StickyForm', StickyForm);
components.set('StickyNavigation', StickyNavigation);
components.set('StocksCards', StocksCards);
components.set('StocksFilter', StocksFilter);
components.set('Styleguide-ComponentParams', StyleguideComponentParams);
components.set('Styleguide-CustomRouteType', StyleguideCustomRouteType);
components.set('Styleguide-EditFrame', StyleguideEditFrame);
components.set('Styleguide-Layout-Reuse', StyleguideLayoutReuse);
components.set('Styleguide-Layout-Tabs-Tab', StyleguideLayoutTabsTab);
components.set('Styleguide-Layout-Tabs', StyleguideLayoutTabs);
components.set('Styleguide-Layout', StyleguideLayout);
components.set('Styleguide-Multilingual', StyleguideMultilingual);
components.set('Styleguide-RouteFields', StyleguideRouteFields);
components.set('Styleguide-Section', StyleguideSection);
components.set('Styleguide-SitecoreContext', StyleguideSitecoreContext);
components.set('Styleguide-Specimen', StyleguideSpecimen);
components.set('Styleguide-Tracking', StyleguideTracking);
components.set('SubcriptionPlans', SubcriptionPlans);
components.set('TabbedProductWork', TabbedProductWork);
components.set('TabbedBlogCarousal', TabbedBlogCarousal);
components.set('TabbedFAQ', TabbedFAQ);
components.set('TabbedProductCategoryListing', TabbedProductCategoryListing);
components.set('Testimonials', Testimonials);
components.set('TestimonialSlider', TestimonialSlider);
components.set('ThingsToKeepInMind', ThingsToKeepInMind);
components.set('react-nouislider', reactNouislider);
components.set('ThreeCardsBanner', ThreeCardsBanner);
components.set('BottomTicker', BottomTicker);
components.set('Countdown', Countdown);
components.set('Ticker', Ticker);
components.set('TimeLineSteps', TimeLineSteps);
components.set('HeadingSubHeadingWithBackgroundImage', HeadingSubHeadingWithBackgroundImage);
components.set('TrendingStocksTable', TrendingStocksTable);
components.set('TrustBanner', TrustBanner);
components.set('UnderstandLifeInsurance', UnderstandLifeInsurance);
components.set('UPI123Section', UPI123Section);
components.set('WhyLifeInsurance', WhyLifeInsurance);
components.set('YellowCardsSlider', YellowCardsSlider);

const componentBuilder = new ComponentBuilder({ components });

export const componentFactory = componentBuilder.getComponentFactory();
