import React, { useState, useEffect } from 'react';
import GraphQLData from '../../lib/GraphQLData';
import { loader as gqlLoader } from 'graphql.macro';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { handleMainNavClick } from '../../lib/customGA4';

const QuicServiceQuery = gqlLoader('./Header.graphql');
const MegaMenuQuickServices = (props) => {
  const isShow = props?.isShow;
  const quickServices = props?.quick?.quick_services?.children;

  const onQuickServiceLoad = props?.onQuickServiceLoad;

  useEffect(() => {
    if (quickServices) {
      onQuickServiceLoad(quickServices);
    }
  }, [quickServices]);

  return (
    <div
      className={`quckserives_megamenu_wrapper box-wrapper ${props?.navStickyClass} ${
        isShow ? 'megamenu_open' : ''
      } ${props?.ticker ? 'isTicker' : ''}`}
      id="quick_services_wrapper_id"
    >
      <div className="loan_megamenu_container">
        <div className="loan_options_wrapper">
          <div className="loan_lists_wrapper">
            <div className="loan_plans_Wrapper">
              <div className="terms_list_wrapper">
                <div className="terms_list_grid">
                  {quickServices?.map((item) => (
                    <div className="card border rounded-3 p-4" key={item.id}>
                      <span className="term-card-heading">
                        <Image
                          field={item.menuIcon}
                          className="quick_service_icon mr-3"
                          alt="img"
                          loading="lazy"
                        />
                        {item.menuTitle?.value}
                      </span>
                      <ul className="term_list-mega_menu">
                        {item.nav_links?.map((link, index) => (
                          <li key={index}>
                            <a
                              id="quickServicesItem"
                              href={link.link?.url}
                              target={link.link?.target}
                              className="cursor-pointer"
                              onClick={() =>
                                handleMainNavClick(
                                  props?.clickedTab,
                                  item.menuTitle?.value,
                                  link.label?.value
                                )
                              }
                            >
                              {link.label?.value}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphQLData(QuicServiceQuery, { name: 'quick' })(MegaMenuQuickServices);
