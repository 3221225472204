import React from 'react';
import GraphQLData from '../../lib/GraphQLData';
import { loader as gqlLoader } from 'graphql.macro';

const FinancialSolutionsQuery = gqlLoader('./FinancialSolutions.graphql');

const FinancialSolutions = (props) => {
  const financialSolutions = props?.financialsolutions?.datasource?.children;
  const displayName = props?.financialsolutions?.datasource?.children[0].displayName;
  return (
    <div className="our_fin_sol_wrapper">
      <span className="financial-heading-megamenu">{displayName}</span>
      <div className="fin_sol_grid">
        {financialSolutions?.map((x) => {
          return (
            <div key={x.id} className="fin_sol_box">
              <img src={x.icon.src} alt="img" loading="lazy" />
              <p>{x.menuTitle.value}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GraphQLData(FinancialSolutionsQuery, { name: 'financialsolutions' })(
  FinancialSolutions
);
