import React, { useEffect } from 'react';
import GraphQLData from '../../lib/GraphQLData';
import { loader as gqlLoader } from 'graphql.macro';
import { withTranslation } from 'react-i18next';
import { Image } from '@sitecore-jss/sitecore-jss-react';

const DisclaimerMessageQuery = gqlLoader('./DisclaimerMessage.graphql');

const DisclaimerMessage = (props) => {
  const disclaimermessage = props?.disclaimermessage?.datasource?.children[0];
  useEffect(() => {
    if (disclaimermessage?.messageSwitch?.value === '1') {
      props.handleDisclaimerVisible(true);
    }
  }, [props]);

  useEffect(() => {
    const closeBtn = document.getElementById('close_btn_white');
    if (closeBtn) {
      closeBtn.addEventListener('click', handleClose);
    }

    return () => {
      if (closeBtn) {
        closeBtn.removeEventListener('click', handleClose);
      }
    };
  }, []);

  const handleClose = () => {
    const appDisclaimer = document.querySelector('.app_disclaimer');
    const topVerticalSlider = document.querySelector('.top_vertical_slider');
    const mobMegMenuWrapper = document.querySelector('.mob_meg_menu_wrapper');

    if (appDisclaimer) {
      appDisclaimer.classList.add('disclaimer_close');
    }
    if (topVerticalSlider) {
      topVerticalSlider.classList.add('disclaimer_close');
    }
    if (mobMegMenuWrapper) {
      mobMegMenuWrapper.classList.add('disclaimer_close_top');
    }
  };

  return (
    <div
      className={`app_disclaimer ${
        disclaimermessage?.messageSwitch?.value === '1' ? '' : 'd-none'
      } ${props?.menuOpen ? 'disclaimer_close' : ''}`}
    >
      <Image
        field={disclaimermessage?.messageIcon}
        className="mob_app"
        width="110"
        height="111"
        loading="lazy"
        alt="img"
      />
      <p>{disclaimermessage?.messageText?.value}</p>
      <a href={disclaimermessage?.messageButtonLink?.url} className="playstore_btn">
        <img src={disclaimermessage?.messageButtonIcon?.src} alt="img" loading="lazy" />
        {disclaimermessage?.messageButtonLink?.text}
      </a>
      <a href="javascript:;" id="close_btn_white" onClick={props.handleCloseClick}>
        <img src={disclaimermessage?.crossIcon?.src} alt="img" loading="lazy" />
      </a>
    </div>
  );
};

export default withTranslation()(
  GraphQLData(DisclaimerMessageQuery, { name: 'disclaimermessage' })(DisclaimerMessage)
);
