import { RichText, Text, Image } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState } from 'react';
import { DotLottiePlayer, Controls } from '@dotlottie/react-player';

const Loader = (props) => {
  useEffect(() => {
    const pageOverlay = document.querySelector('.pageOverlay');
    const logoloading = document.querySelector('.logo-loading');
    if (pageOverlay) {
      const removeLogoLoading = setTimeout(() => {
        logoloading.classList.add('remove');
      }, 3800);

      const removePageOverlay = setTimeout(() => {
        pageOverlay.classList.add('remove');
      }, 4000);

      return () => {
        clearTimeout(removeLogoLoading);
        clearTimeout(removePageOverlay);
      };
    }
  }, []);

  return (
    <>
      <div className="pageOverlay"></div>
      <div>
        <DotLottiePlayer
          className="logo-loading"
          src="https://lottie.host/562bc63e-6700-4038-87e3-1e835197230e/EQKt4uxNtm.lottie"
          background="transparent"
          speed={1}
          autoplay
          loop
        ></DotLottiePlayer>
      </div>
    </>
  );
};

export default Loader;
