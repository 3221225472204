import axios from 'axios';

const SubscribeToNewsLetter = (email) => {
  //   e.preventDefault();
  const data = {
    email: email,
  };

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_SUBSCRIBE_NEWSLETTER}`,
    headers: {
      'x-functions-key': `${process.env.REACT_APP_SUBSCRIBE_NEWSLETTER_TOKEN}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(data),
  };

  return axios
    .request(config)
    .then((response) => {
      const { status, message } = response.data;
      switch (status) {
        case 0:
          return message;
        case 1:
          return message;
        case -1:
          return message;
        default:
          return status;
      }
    })
    .catch((error) => {
      throw error;
    });
};

export default SubscribeToNewsLetter;
