import React, { useState, useEffect } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import deepEqual from 'deep-equal';
import { Helmet } from 'react-helmet';
import Header from './components/navigation/Header';
import SideMenu from './components/side-menu/SideMenu';
import Loader from './components/Loader/Loader';
import ScrollTop from './components/scroll-top/ScrollTop';
import StructuredData from './StructuredData';
import FixedBottomDrawer from './components/FixedBottomDrawer/FixedBottomDrawer';

const Layout = ({ route }) => {
  const isHomePage = route.name === 'home'; // Adjust this condition based on your route structure
  const canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );

  const {
    MetaTitle,
    MetaDescription,
    MetaKeywords,
    CanonicalLinks,
    GTMCode,
    PreSiteScripts,
    PostSiteScripts,
    RobotsIndex,
    RobotsFollow,
    WebSchema,
    OrgSchema,
    BreacrumbSchema,
    FAQSchema,
    VideoSchema,

    OgTitle,
    OgDescription,
    OgImage,
    OgType,
    OgUrl,

    TwitteTitle,
    TwitterCards,
    TwitterDescription,
    TwitterImage,
  } = route?.fields || {};

  useEffect(() => {
    // Dynamically append PreSiteScripts to the end of the head
    if (PreSiteScripts?.value) {
      const parser = new DOMParser();
      const scriptsDoc = parser.parseFromString(PreSiteScripts.value, 'text/html');
      const scripts = scriptsDoc.getElementsByTagName('script');

      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement('script');
        newScript.type = script.type;
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        document.head.appendChild(newScript);
      });
    }
  }, [PreSiteScripts?.value]);

  useEffect(() => {
    // Dynamically append PostSiteScripts to the end of the body
    if (PostSiteScripts?.value) {
      const parser = new DOMParser();
      const scriptsDoc = parser.parseFromString(PostSiteScripts.value, 'text/html');
      const scripts = scriptsDoc.getElementsByTagName('script');

      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement('script');
        newScript.type = script.type;
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        document.body.appendChild(newScript);
      });
    }
  }, [PostSiteScripts?.value]);

  // New Schema

  useEffect(() => {
    if (WebSchema?.value) {
      const parser = new DOMParser();
      const scriptsDoc = parser.parseFromString(WebSchema.value, 'text/html');
      const scripts = scriptsDoc.getElementsByTagName('script');

      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement('script');
        newScript.type = script.type;
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        document.head.appendChild(newScript);
      });
    }
  }, [WebSchema?.value]);

  useEffect(() => {
    if (OrgSchema?.value) {
      const parser = new DOMParser();
      const scriptsDoc = parser.parseFromString(OrgSchema.value, 'text/html');
      const scripts = scriptsDoc.getElementsByTagName('script');

      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement('script');
        newScript.type = script.type;
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        document.head.appendChild(newScript);
      });
    }
  }, [OrgSchema?.value]);

  useEffect(() => {
    if (BreacrumbSchema?.value) {
      const parser = new DOMParser();
      const scriptsDoc = parser.parseFromString(BreacrumbSchema.value, 'text/html');
      const scripts = scriptsDoc.getElementsByTagName('script');

      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement('script');
        newScript.type = script.type;
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        document.head.appendChild(newScript);
      });
    }
  }, [BreacrumbSchema?.value]);

  useEffect(() => {
    if (FAQSchema?.value) {
      const parser = new DOMParser();
      const scriptsDoc = parser.parseFromString(FAQSchema.value, 'text/html');
      const scripts = scriptsDoc.getElementsByTagName('script');

      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement('script');
        newScript.type = script.type;
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        document.head.appendChild(newScript);
      });
    }
  }, [FAQSchema?.value]);

  useEffect(() => {
    if (VideoSchema?.value) {
      const parser = new DOMParser();
      const scriptsDoc = parser.parseFromString(VideoSchema.value, 'text/html');
      const scripts = scriptsDoc.getElementsByTagName('script');

      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement('script');
        newScript.type = script.type;
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        document.head.appendChild(newScript);
      });
    }
  }, [VideoSchema?.value]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const buttonCloseTicker = document.querySelector('.ticker_close');
      const removeClassDiv = document.querySelector('.ticker-header-parent');

      const handleButtonClick = () => {
        if (removeClassDiv) {
          removeClassDiv.classList.remove('tickerOpen-parent');
        }
      };

      const handleScroll = () => {
        if (removeClassDiv) {
          const scrollPositionY = window.scrollY;
          if (scrollPositionY > 0) {
            removeClassDiv.classList.add('tickerOpen-parent-scroll');
          } else {
            removeClassDiv.classList.remove('tickerOpen-parent-scroll');
          }
        }
      };

      if (buttonCloseTicker) {
        buttonCloseTicker.addEventListener('click', handleButtonClick);
      }
      window.addEventListener('scroll', handleScroll);

      // Cleanup event listeners on unmount
      return () => {
        if (buttonCloseTicker) {
          buttonCloseTicker.removeEventListener('click', handleButtonClick);
        }
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const currentUrl = canUseDOM ? window.location.href : '';

  return (
    <React.Fragment>
      <Helmet>
        <title>{MetaTitle?.value || 'Page'}</title>
        <meta name="description" content={MetaDescription?.value || ''} />
        <meta name="keywords" content={MetaKeywords?.value || ''} />

        <meta property="og:title" content={OgTitle?.value} />
        <meta property="og:description" content={OgDescription?.value} />
        <meta property="og:image" content={OgImage?.value?.src} />
        <meta property="og:type" content={OgType?.value} />
        <meta property="og:url" content={OgUrl?.value} />

        <meta name="twitter:card" content={TwitterCards?.value} />
        <meta name="twitter:title" content={TwitteTitle?.value} />
        <meta name="twitter:description" content={TwitterDescription?.value} />
        <meta name="twitter:image" content={TwitterImage?.value?.src} />

        <link rel="stylesheet" type="text/css" href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/bootstrap.min.css" media="all" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/swiper-bundle.min.css"
          media="all"
        />
        <link rel="stylesheet" type="text/css" href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/aos.css" media="all" />
        <link rel="stylesheet" type="text/css" href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/abc_footer.css" media="all" />
        {!isHomePage && [
          <link
            key="nouislider"
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/noUiSlider/15.7.1/nouislider.css"
          />,
          <link
            key="style"
            rel="stylesheet"
            type="text/css"
            href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/style.css"
            media="all"
          />,
          <link
            key="categorypage-style"
            rel="stylesheet"
            type="text/css"
            href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/categorypage-style.css"
            media="all"
          />,
          <link
            key="navigation"
            rel="stylesheet"
            type="text/css"
            href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/navigation.css"
            media="all"
          />,
          <link
            key="common"
            rel="stylesheet"
            type="text/css"
            href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/common.css"
            media="all"
          />,
        ]}

        {route?.fields?.styleIncludes?.value?.split('|')?.map((x, i) => {
          return <link key={`linkel-${i}`} rel="stylesheet" type="text/css" href={x} media="all" />;
        })}

        {!isHomePage && (
          <link rel="stylesheet" type="text/css" href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/enquiry-now.css" media="all" />
        )}
        <link rel="stylesheet" type="text/css" href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/jss-override.css" media="all" />
        {CanonicalLinks?.value && <link rel="canonical" href={CanonicalLinks?.value?.href} />}

        {RobotsIndex.value && RobotsFollow.value && <meta name="robots" content="index,follow" />}
        {!RobotsIndex.value && RobotsFollow.value && (
          <meta name="robots" content="noindex,follow" />
        )}
        {RobotsIndex.value && !RobotsFollow.value && (
          <meta name="robots" content="index,nofollow" />
        )}

        {!RobotsIndex.value && !RobotsFollow.value && (
          <meta name="robots" content="noindex,nofollow" />
        )}

        {GTMCode?.value && (
          <script>
            {`
            (function(w,d,s,l,i){
              w[l]=w[l]||[];
              w[l].push({
                'gtm.start': new Date().getTime(),
                event:'gtm.js'
              });
              var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),
              dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.defer = true;
              j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTMCode?.value}');
          `}
          </script>
        )}
      </Helmet>
      <FixedBottomDrawer />
      <div className="ticker-header-parent tickerOpen-parent">
        <Header />
        {!isHomePage && <SideMenu />}
      </div>
      <ScrollTop />
      {/* <StructuredData url={currentUrl} route={route} /> */}
      <div>
        <Placeholder name="jss-main" rendering={route} />
      </div>
      {isHomePage && <Loader />}
    </React.Fragment>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;
  return false;
};

export default React.memo(Layout, propsAreEqual);
