import React, { useState, useEffect } from 'react';
import SideMenuOurCompanies from './SideMenu-OurCompanies';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import GraphQLData from '../../lib/GraphQLData';
import { loader as gqlLoader } from 'graphql.macro';
import SideMenuDownloadApp from './SideMenuDownloadApp';
import DownloadAppModal from '../HomepageAppHero/DownloadAppModal';

const SideMenuQuery = gqlLoader('./SideMenu.graphql');

const SideMenu = (props) => {
  const [currentTab, setCurrentTab] = useState(null);
  const [currentAccordion, setCurrentAccordion] = useState(
    props?.sitecoreContext?.route?.fields?.pageTitle?.value
  );
  const [activeModal, setActiveModal] = useState('');

  useEffect(() => {
    const findCurrentTab = () => {
      const matchingCategory = props?.data?.tabs?.children.find((category) => {
        return category.children.some((subcategory) => {
          return (
            subcategory.heading.value === currentAccordion ||
            subcategory.products.some((product) => product.productName.value === currentAccordion)
          );
        });
      });

      if (matchingCategory) {
        setCurrentTab(matchingCategory.tabName.value);
      }
    };

    findCurrentTab();
  }, [props, currentAccordion]);

  const changeActiveModal = (newModal) => {
    setActiveModal(newModal);
  };
  return (
    <>
      <div className="right_sticky_options_wrapper">
        <ul className="right_option_list">
          {props?.data?.item?.children?.map((item, itemIndex) => {
            const isAnchor = item?.CTA?.linkType === 'anchor';
            const isModal = item?.CTA?.className === 'modal';
            const isExternal = item?.CTA?.linkType === 'external';
            return (
              <li key={itemIndex} className="rightNavAbcd-logo">
                <a
                  href={isAnchor ? 'javascript:;' : item?.CTA?.url}
                  target={isExternal ? item?.CTA?.target : undefined}
                  data-bs-toggle={isAnchor ? 'modal' : undefined}
                  data-bs-target={isAnchor ? item?.CTA?.url : undefined}
                  data-tab={isAnchor && isModal ? currentTab : undefined}
                  data-accordion={isAnchor && isModal ? currentAccordion : undefined}
                  data-position="secondary_navigation"
                  className="downloadLink"
                >
                  <img
                    src={item?.Icon?.src}
                    alt={item?.Label?.value}
                    className="mob_app_right downloadImage"
                  />
                  {item?.IconSecond?.src && (
                    <img
                      src={item?.IconSecond?.src}
                      alt={item?.IconSecond?.alt}
                      className="mob_app_right hover-img"
                    />
                  )}
                  <Text field={item?.Label} editable />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <SideMenuOurCompanies activeModal={activeModal}></SideMenuOurCompanies>
      <DownloadAppModal />
    </>
  );
};

export default GraphQLData(SideMenuQuery, { name: 'data' })(SideMenu);
