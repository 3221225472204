import React, { useState, useEffect, Children } from 'react';
import DisclaimerMessage from './DisclaimerMessage';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import BottomTicker from '../ticker/BottomTicker';

const MobileNavigation = ({
  navigationResult,
  toolsData,
  quickServiceData,
  headerRightSide,
  topNavigationProps,
  drawer,
  getMenuOpen,
  mainLogo,
  bannerCountdownItem
}) => {
  const loginItem = headerRightSide?.children?.find((item) => item.Label.value === 'Login');

  const [searchOpen, setSearchOpen] = useState(false);

  const [disclaimerClosed, setDisclaimerClosed] = useState(false);
  const [disclaimerVisible, setDisclaimerVisible] = useState(true);

  const [menuOpen, setMenuOpen] = useState(false);

  const [activeIndex, setActiveIndex] = useState(null);

  const [toolsVisible, setToolsVisible] = useState(false);
  const [quickServiceVisible, setQuickServiceVisible] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleToolsClick = () => {
    setToolsVisible(true);
    setQuickServiceVisible(false);
  };

  const handleQuickServiceClick = () => {
    setQuickServiceVisible(true);
    setToolsVisible(false);
  };

  const handleBackClick = () => {
    setToolsVisible(false);
    setQuickServiceVisible(false);
  };

  useEffect(() => {
    if (menuOpen) {
      setDisclaimerVisible(false);
    } else {
      setDisclaimerVisible(true);
    }
  }, [menuOpen]);

  const handleMenuToggle = () => {
    setMenuOpen((prevState) => !prevState);
    getMenuOpen((prevState) => !prevState);
  };

  const handleCloseClick = () => {
    setDisclaimerClosed(true);
  };

  const handleDisclaimerVisible = (messageSwitch) => {
    messageSwitch ? setDisclaimerVisible(true) : setDisclaimerVisible(false);
  };

  const handleSearchClick = () => {
    setSearchOpen(!searchOpen);
  };

  const handleCollapseClick = (index, e) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const searchData = headerRightSide?.children?.filter((item) => {
    return item.Icon && item.CssClass.value && item.CssClass.value.includes('search_ico');
  });

  const corporatesData = topNavigationProps?.children[1].children?.slice(0, 4).filter((item) => {
    return item.mobileOnly.value !== '1' && item.menuTitle.value === 'Corporates';
  });

  const advirsorsData = topNavigationProps?.children[1].children?.slice(0, 4).filter((item) => {
    return item.mobileOnly.value !== '1' && item.menuTitle.value === 'Advisors';
  });

  const [topNavs, setTopNavs] = useState(0);
  const [topNavsLength, setTopNavsLength] = useState(0);

  useEffect(() => {
    setTopNavs(
      topNavigationProps?.children[1]?.children
        ?.slice(0, -1)
        .filter((item) => item?.mobileOnly?.value !== '1')
    );
    setTopNavsLength(topNavs?.length);
  }, [topNavigationProps]);
  return (
    <div className="mob_nav header_mob_nav hidden-md hidden-lg">
      <DisclaimerMessage
        disclaimerVisible={disclaimerVisible}
        disclaimerClosed={disclaimerClosed}
        handleCloseClick={handleCloseClick}
        handleDisclaimerVisible={handleDisclaimerVisible}
        menuOpen={menuOpen}
      />
      <div className={`mob_meg_menu_wrapper ${menuOpen ? 'disclaimer_close_top' : ''}`}>
        <div className="mob_nav_left">
          <div
            className={`hamburger ${menuOpen ? 'is-active' : ''}`}
            id="hamburger-1"
            onClick={handleMenuToggle}
          >
            <span className="line" />
            <span className="line" />
            <span className="line" />
          </div>
          <a href="/" className="mob_brand_log">
            <Image field={mainLogo?.company_log[0]?.Icon} loading="lazy" />
          </a>
        </div>
        <div className="mob_nav_rightWrap">
          <div className="mob_nav_right">
            {searchData &&
              searchData?.map((item, index) => (
                <div key={index}>
                  <p>{item.Label.value}</p>
                  <img src={item.Icon.src} alt={item.Label.value} loading="lazy" />
                </div>
              ))}
            <a href="tel:18002707000" className="call_ico_mob">
              <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/call_dark.svg" loading="lazy" />
            </a>
            {loginItem && (
              <a href={loginItem?.CTA?.url} className="userprof_mob">
                <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/user_red.svg" loading="lazy" />
              </a>
            )}
          </div>
        </div>
        <div className={`menu_list_wrapper ${menuOpen ? 'menu_list_wrapper_open' : ''}`}>
          <div className="menu_list_horizontal">
            <ul
              className={`mlh_list ${
                topNavsLength === 3 ? 'mlh_list_three' : topNavsLength === 5 ? 'mlh_list_five' : ''
              } ${toolsVisible || quickServiceVisible ? 'd-none' : ''}`}
              id="nav_pills-tab"
              role="tablist"
            >
              {topNavs &&
                topNavs?.map((navItem, index) => (
                  <li key={index} className="nav-item" role="presentation">
                    {navItem?.menuLink?.url ? (
                      <button className="nav-link">
                        <a className="outlinkanchor" href={navItem?.menuLink?.url}>
                          {navItem?.displayName}
                          <span className="outlinkArroe">
                            <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/external-link.svg" loading="lazy" />
                          </span>
                        </a>
                      </button>
                    ) : (
                      <button
                        className={`nav-link ${index === 0 ? 'active' : ''}`}
                        id={`#nav_pills-${navItem?.displayName?.toLowerCase()}-id`}
                        data-bs-toggle="pill"
                        data-bs-target={`#nav_pills-${navItem?.displayName?.toLowerCase()}`}
                        type="button"
                        role="tab"
                        aria-controls={`#nav_pills-${navItem?.displayName?.toLowerCase()}`}
                        aria-selected="true"
                      >
                        {navItem?.displayName}
                      </button>
                    )}
                  </li>
                ))}
            </ul>
            <div
              className={`insurances_back nav_sub_back nav_sub_back_arrow ${
                toolsVisible ? '' : 'd-none'
              }`}
            >
              <a href="javascript:void(0)" onClick={handleBackClick}>
                <span>
                  <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/navback-arow.svg" loading="lazy" />
                </span>
                Tools
              </a>
            </div>
            <div
              className={`investments_back nav_sub_back nav_sub_back_arrow ${
                quickServiceVisible ? '' : 'd-none'
              }`}
              onClick={handleBackClick}
            >
              <a href="javascript:void(0)">
                <span>
                  <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/navback-arow.svg" loading="lazy" />
                </span>
                Quick Services
              </a>
            </div>
            <div className="loans_back nav_sub_back d-none">
              <a href="javascript:void(0)">
                <span>
                  <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/chevron_left_red.svg" loading="lazy" />
                </span>
                LOANS
              </a>
            </div>
            <div className="resources_back nav_sub_back d-none">
              <a href="javascript:void(0)">
                <span>
                  <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/chevron_left_red.svg" loading="lazy" />
                </span>
                RESOURVES
              </a>
            </div>
            <div
              className={`nav-tab-content-wrapper ${
                toolsVisible || quickServiceVisible ? 'd-none' : ''
              }`}
              id="nav_pills-tabContent"
            >
              <div
                className="tab-pane fade show active"
                id="nav_pills-personal"
                role="tabpanel"
                aria-labelledby="nav_pills-personal-tab"
              >
                <div className="accordian_wrapper_mb">
                  <div className="accordion" id="accordionMobNavgsn">
                    {navigationResult?.primary?.map((item, index) => (
                      <div className="accordion-item" key={index}>
                        <div className="accordion-header" id={`headingNav${index + 1}`}>
                          <button
                            className={`accordion-button ${
                              activeIndex === index ? '' : 'collapsed'
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseNav${index + 1}`}
                            aria-expanded={index === 0 ? 'true' : 'false'}
                            aria-controls={`collapseNav${index + 1}`}
                            onClick={() => handleCollapseClick(index)}
                          >
                            <span>
                              <img src={item.menuIcon?.src} alt={`icon-${index}`} loading="lazy" />
                            </span>
                            {item.menuTitle?.value}
                          </button>
                        </div>
                        <div
                          id={`collapseNav${index + 1}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`headingNav${index + 1}`}
                          data-bs-parent="#accordionMobNavgsn"
                        >
                          <div className="accordion-body">
                            {item?.tabs?.map((subItem, subIndex) => (
                              <div className="nav_body_wrapper" key={subIndex}>
                                <div className="nav_sub_main_tab_wrapper">
                                  <ul className="nav_sub_tab_list_wrapper">
                                    <li className="sub_tab_head">
                                      <a href={subItem?.menuLink?.url}>
                                        {subItem?.menuTitle?.value}
                                        <span className="redi_arrow">
                                          <img
                                            src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/navRedi-arrow.svg"
                                            alt="arrow-icon"
                                            loading="lazy"
                                          />
                                        </span>
                                      </a>
                                    </li>
                                    {subItem?.secondary?.map((subTabItem, subTabIndex) => (
                                      <li className="sub_tab_list" key={subTabIndex}>
                                        <a href={subTabItem?.menuLink?.url}>
                                          {subTabItem?.menuTitle?.value}
                                        </a>
                                      </li>
                                    ))}
                                    {subItem?.menuLink?.url && subItem?.menuLink?.text && (
                                      <li className="sub_tab_list viewall-CTA">
                                        <a href={subItem?.menuLink?.url}>
                                          {subItem?.menuLink?.text}
                                        </a>
                                      </li>
                                    )}

                                    {subItem?.yellowSubTab?.value && (
                                      <RichText field={subItem?.yellowSubTab} editable />
                                    )}
                                    {subItem?.viewAllLink?.url && subItem?.viewAllLink?.text && (
                                      <li className="sub_tab_list viewall-CTA">
                                        <a href={subItem?.viewAllLink?.url}>
                                          {subItem?.viewAllLink?.text}
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <ul className="nav_sub_list_wrapper">
                  <li className="nav_sub_list_li">
                    <a href="javascript:void(0)" id="insurance_li">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico1.svg" loading="lazy" /> Insurances{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" id="investments_li">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico2.svg" loading="lazy" /> INVESTMENTS{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" id="loans_li">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico3.svg" loading="lazy" /> LOANS{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico4.svg" loading="lazy" /> PAYMENTS{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" id="resources_li">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico2.svg" loading="lazy" /> RESOURCES{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico5.svg" loading="lazy" /> QUICK SERVICES{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                </ul>
                <div className="nav_outlinks_wrpper">
                  <ul>
                    <li>
                      <a href="javascript:;" id="tools_li" onClick={handleToolsClick}>
                        Tools <span className="chevron_right_dark col_red" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:;"
                        id="quickservices_li"
                        onClick={handleQuickServiceClick}
                      >
                        Quick Services <span className="chevron_right_dark col_red" />
                      </a>
                    </li>

                    <li>
                      <a href="/about-us">
                        About Us{' '}
                        <span className="outlinkArroe">
                          <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/external-link.svg" loading="lazy" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mobbtnGroup-mb">
                  <a
                    className="mobbtn-anch getintouch-xm"
                    data-bs-toggle="modal"
                    data-bs-target="#get_in_touch_modal2"
                  >
                    GET IN TOUCH
                  </a>
                  <a href={loginItem?.CTA?.url} className="mobbtn-anch login-xm">
                    LOGIN
                  </a>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav_pills-corporates"
                role="tabpanel"
                aria-labelledby="nav_pills-corporates-tab"
              >
                <div className="accordian_wrapper_mb">
                  <div className="accordion" id="accordionMobNavgsn2">
                    {corporatesData &&
                      corporatesData[0]?.top_nav_tab?.map((item, index) => (
                        <div className="accordion-item" key={index}>
                          <div className="accordion-header" id={`headingNav-corp-${index}`}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#navCoraporates${index + 1}`}
                              aria-expanded="false"
                              aria-controls={`navCoraporates${index + 1}`}
                            >
                              <span>
                                <img
                                  src={`/assets/images/nav-ico${index + 1}.svg`}
                                  alt={`Icon ${index + 1}`}
                                  loading="lazy"
                                />
                              </span>
                              {item.Heading.value}
                            </button>
                          </div>
                          <div
                            id={`navCoraporates${index + 1}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`headingNav-corp-${index}`}
                            data-bs-parent="#accordionMobNavgsnCorporates"
                          >
                            <div className="accordion-body">
                              <div className="nav_body_wrapper">
                                <div className="nav_sub_main_tab_wrapper">
                                  <ul className="nav_sub_tab_list_wrapper">
                                    {item.top_nav_tab_quicklinks.map((link, subIndex) => (
                                      <li className="sub_tab_list" key={subIndex}>
                                        <a href={link.Link.url}>{link.Label.value}</a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <ul className="nav_sub_list_wrapper">
                  <li className="nav_sub_list_li">
                    <a href="javascript:void(0)" id="insurance_li">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico1.svg" loading="lazy" /> Insurances{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" id="investments_li">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico2.svg" loading="lazy" /> INVESTMENTS{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" id="loans_li">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico3.svg" loading="lazy" /> LOANS{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico4.svg" loading="lazy" /> PAYMENTS{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" id="resources_li">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico2.svg" loading="lazy" /> RESOURCES{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico5.svg" loading="lazy" /> QUICK SERVICES{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                </ul>
                <div className="nav_outlinks_wrpper d-none">
                  <ul>
                    <li>
                      <a href="javascript:;" id="tools_li" onClick={handleToolsClick}>
                        Tools <span className="chevron_right_dark col_red" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:;"
                        id="quickservices_li"
                        onClick={handleQuickServiceClick}
                      >
                        Quick Services <span className="chevron_right_dark col_red" />
                      </a>
                    </li>

                    <li>
                      <a href="javascript:;">
                        About Us{' '}
                        <span className="outlinkArroe">
                          <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/external-link.svg" loading="lazy" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav_pills-advisors"
                role="tabpanel"
                aria-labelledby="nav_pills-advisors-tab"
              >
                <div className="accordian_wrapper_mb">
                  <div className="accordion" id="accordionMobNavgsn4">
                    {advirsorsData &&
                      advirsorsData[0]?.top_nav_tab?.map((item, index) => (
                        <div className="accordion-item" key={index}>
                          <div className="accordion-header" id={`headingNav-advisor-${index}`}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#navAdvisors${index + 1}`}
                              aria-expanded="false"
                              aria-controls={`navAdvisors${index + 1}`}
                            >
                              <span>
                                <img
                                  src={`/assets/images/nav-ico${index + 1}.svg`}
                                  alt={`Icon ${index + 1}`}
                                  loading="lazy"
                                />
                              </span>
                              {item.Heading.value}
                            </button>
                          </div>
                          <div
                            id={`navAdvisors${index + 1}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`headingNav-advisor-${index}`}
                            data-bs-parent="#accordionMobNavgsnAdvisors"
                          >
                            <div className="accordion-body">
                              <div className="nav_body_wrapper">
                                <div className="nav_sub_main_tab_wrapper">
                                  <ul className="nav_sub_tab_list_wrapper">
                                    {item.top_nav_tab_quicklinks.map((link, subIndex) => (
                                      <li className="sub_tab_list" key={subIndex}>
                                        <a href={link.Link.url}>{link.Label.value}</a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <ul className="nav_sub_list_wrapper d-none">
                  <li className="nav_sub_list_li">
                    <a href="javascript:void(0)" id="insurance_li">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico1.svg" loading="lazy" /> Insurances{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" id="investments_li">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico2.svg" loading="lazy" /> INVESTMENTS{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" id="loans_li">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico3.svg" loading="lazy" /> LOANS{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico4.svg" loading="lazy" /> PAYMENTS{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" id="resources_li">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico2.svg" loading="lazy" /> RESOURCES{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico5.svg" loading="lazy" /> QUICK SERVICES{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                </ul>
                <div className="nav_outlinks_wrpper">
                  <ul>
                    <li>
                      <a href="javascript:;" id="tools_li" onClick={handleToolsClick}>
                        Tools <span className="chevron_right_dark col_red" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:;"
                        id="quickservices_li"
                        onClick={handleQuickServiceClick}
                      >
                        Quick Services <span className="chevron_right_dark col_red" />
                      </a>
                    </li>

                    <li>
                      <a href="javascript:;">
                        About Us{' '}
                        <span className="outlinkArroe">
                          <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/external-link.svg" loading="lazy" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={`nav_sub_tab_wrapper ${
                toolsVisible || quickServiceVisible ? 'd-none' : ''
              }`}
              id="insurance_sub_tab"
            >
              <div className="nav_sub_main_tab_wrapper">
                <ul className="nav_sub_tab_list_wrapper">
                  <li className="sub_tab_head">
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico1.svg" loading="lazy" /> LIFE INSURANCE{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Term Plan <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Savings Plan <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Child Plan <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Retirement Plan <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      ULIP <span className="chevron_right_dark" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="nav_sub_main_tab_wrapper">
                <ul className="nav_sub_tab_list_wrapper">
                  <li className="sub_tab_head">
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/form_ico2.svg" loading="lazy" /> HEALTH INSURANCE{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Health &amp; Wellness Plan <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Cancer Secure Plan <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Critical Illness Plan <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Personal Accident Plan <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Corporate Plan <span className="chevron_right_dark" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={`nav_sub_tab_wrapper nav_sub_tab_wrapper-mob-new ${
                toolsVisible ? '' : 'd-none'
              }`}
              id="tools_sub_tab"
            >
              <div className="nav_body_wrapper">
                <div className="nav_sub_main_tab_wrapper">
                  {toolsData?.first?.map((tab) => (
                    <ul className="nav_sub_tab_list_wrapper" key={tab?.MenuTitle?.value}>
                      <li className="sub_tab_head">
                        <a href={tab?.MenuLink?.url}>{tab?.MenuTitle?.value}</a>
                      </li>
                      {tab?.second.map((subTab, index) => {
                        return (
                          <React.Fragment key={index}>
                            {subTab?.third?.length > 0 ? (
                              subTab?.third.map((subSubTab) => {
                                return (
                                  <li className="sub_tab_list" key={subSubTab?.MenuTitle?.value}>
                                    <a href={subSubTab?.MenuLink?.url}>
                                      {subSubTab?.MenuTitle?.value}
                                    </a>
                                  </li>
                                );
                              })
                            ) : (
                              <li className="sub_tab_list" key={index}>
                                <a href={subTab?.MenuLink?.url}>{subTab?.MenuTitle?.value}</a>
                              </li>
                            )}
                          </React.Fragment>
                        );
                      })}
                      <li className="sub_tab_list viewall-CTA">
                        <a href={tab?.MenuLink?.url}>View All</a>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={`nav_sub_tab_wrapper nav_sub_tab_wrapper-mob-new ${
                quickServiceVisible ? '' : 'd-none'
              }`}
              id="quickservices_sub_tab"
            >
              <div className="nav_body_wrapper">
                <div className="nav_sub_main_tab_wrapper">
                  {quickServiceData?.map((tab) => (
                    <ul className="nav_sub_tab_list_wrapper" key={tab?.menuTitle?.value}>
                      <li className="sub_tab_head">
                        <a href={tab?.menuLink?.url}>{tab?.menuTitle?.value}</a>
                      </li>
                      {tab?.nav_links?.map((subTab) => (
                        <li className="sub_tab_list" key={subTab?.label?.value}>
                          <a href={subTab?.link?.url}>{subTab?.label?.value}</a>
                        </li>
                      ))}
                      <li className="sub_tab_list viewall-CTA">
                        <a href={tab?.menuLink?.url}>View All</a>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
            <div className="nav_sub_tab_wrapper d-none" id="investments_sub_tab">
              <div className="nav_sub_main_tab_wrapper">
                <ul className="nav_sub_tab_list_wrapper">
                  <li className="sub_tab_head">
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico1.svg" loading="lazy" /> MUTUAL FUNDS (By
                      Solutions) <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Wealth Creation <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Tax Saving <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Income <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Savings <span className="chevron_right_dark" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="nav_sub_main_tab_wrapper">
                <ul className="nav_sub_tab_list_wrapper">
                  <li className="sub_tab_head">
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico1.svg" loading="lazy" /> MUTUAL FUNDS (By
                      Categories) <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Debt Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Equity Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      ETF Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Hybrid Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Funds of Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Index Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Target Maturity Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="nav_sub_main_tab_wrapper">
                <ul className="nav_sub_tab_list_wrapper">
                  <li className="sub_tab_head">
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico1.svg" loading="lazy" /> Stocks &amp;
                      Securities <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Open Demat Account <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      IPO Calendar <span className="chevron_right_dark" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="nav_sub_tab_wrapper d-none" id="loans_sub_tab">
              <div className="nav_sub_main_tab_wrapper">
                <ul className="nav_sub_tab_list_wrapper">
                  <li className="sub_tab_head">
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico1.svg" loading="lazy" /> HOUSING FINANCE{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Home Loans <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Balance Transfer <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Top-Up Home Loan <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Loan Against Property <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Construction Finance <span className="chevron_right_dark" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="nav_sub_main_tab_wrapper">
                <ul className="nav_sub_tab_list_wrapper">
                  <li className="sub_tab_head">
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico1.svg" loading="lazy" /> PERSONAL FINANCE{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Personal Loans <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Aditya Birla SBI Card <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Flexi Loan <span className="chevron_right_dark" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="nav_sub_main_tab_wrapper">
                <ul className="nav_sub_tab_list_wrapper">
                  <li className="sub_tab_head">
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico1.svg" loading="lazy" /> SME FINANCE{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Line Of Credit <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Unsecured Business Loan <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Secured Business Loan <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Working Capital Demand Loan <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Supply Chain Financing Solutions - Invoice Discounting{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="nav_sub_tab_wrapper d-none" id="resources_sub_tab">
              <div className="nav_sub_main_tab_wrapper">
                <ul className="nav_sub_tab_list_wrapper">
                  <li className="sub_tab_head">
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico1.svg" loading="lazy" /> ABC of Money{' '}
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Debt Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Equity Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      ETF Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Hybrid Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Fund Of Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Index Funds
                      <span className="chevron_right_dark" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="nav_sub_main_tab_wrapper">
                <ul className="nav_sub_tab_list_wrapper">
                  <li className="sub_tab_head">
                    <a href="">
                      <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/nav-ico1.svg" loading="lazy" /> Tools &amp;
                      Calculators <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <span className="wrapper_title_text">Calculators</span>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      SIP Calculator <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      EMI Calculator <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Flexi Loan <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <span className="wrapper_title_text">Money View</span>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Hybrid Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Fund Of Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                  <li className="sub_tab_list">
                    <a href="">
                      Index Funds <span className="chevron_right_dark" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="abc_quick_links_Wrapper abc_quick_links_Wrapper_grid d-none">
              <ul>
                <li>
                  <a className="header_no_border_btn" href="javascript:void(0)">
                    DOWNLOAD{' '}
                    <img src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/abcd_logo.svg" width={22} height={22} loading="lazy" />
                  </a>
                </li>
                <li>
                  <a className="header_no_border_btn flex-reverse-a" href="javascript:void(0)">
                    GET IN TOUCH{' '}
                    <img
                      src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/getInTouch-i.svg"
                      width={22}
                      height={22}
                      loading="lazy"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={`search_result_mob ${searchOpen ? 'mob_search_open' : ''}`}>
          <div className="search_result_horizotal_tabs">
            <div className="nav-tab-search-content-wrapper" id="search_nav_pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="search_nav_pills-home"
                role="tabpanel"
                aria-labelledby="search_nav_pills-home-tab"
              >
                <div className="mob_pop_search_wrapper">
                  <div className="search_mob_input">
                    <input className="form-control" type="text" placeholder="search" />
                    <img
                      src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/cross.svg"
                      className="mob_close_search"
                      id="mob_close_search_btn"
                      loading="lazy"
                    />
                  </div>
                  <div className="mob_search_data_Wrapper">
                    <span className="mob_search_head">Popular Searches</span>
                    <div className="mob_search_tags_Wrapper">
                      <a href="" className="mob_tag_a">
                        Life Insurance
                      </a>
                      <a href="" className="mob_tag_a">
                        Housing Finance
                      </a>
                      <a href="" className="mob_tag_a">
                        Retirement Plan
                      </a>
                      <a href="" className="mob_tag_a">
                        ABSLI Digishield Plan
                      </a>
                      <a href="" className="mob_tag_a">
                        Savings Plan
                      </a>
                      <a href="" className="mob_tag_a">
                        ABSLI Child Future Assured Plan
                      </a>
                    </div>
                  </div>
                  <div className="mob_search_data_Wrapper">
                    <span className="mob_search_head">Trending Plans</span>
                    <div className="mob_search_tags_grid">
                      <a href="" className="img_tag_mob">
                        <img
                          src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_terms_plan.svg"
                          width={20}
                          height={20}
                          loading="lazy"
                        />
                        <div>
                          <span>Term Plan</span>
                          <p>ABSLI Saral Jeevan Bima</p>
                        </div>
                      </a>
                      <a href="" className="img_tag_mob">
                        <img
                          src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_child_plan.svg"
                          width={20}
                          height={20}
                          loading="lazy"
                        />
                        <div>
                          <span>Child Plan</span>
                          <p>ABSLI Vision Star Plan</p>
                        </div>
                      </a>
                      <a href="" className="img_tag_mob">
                        <img
                          src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_savings_plan.svg"
                          width={20}
                          height={20}
                          loading="lazy"
                        />
                        <div>
                          <span>Savings Plan</span>
                          <p>ABSLI Nishchit Aayush Plan</p>
                        </div>
                      </a>
                      <a href="" className="img_tag_mob">
                        <img
                          src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_retirement_plan.svg"
                          width={20}
                          height={20}
                          loading="lazy"
                        />
                        <div>
                          <span>Retirement Plan</span>
                          <p>ABSLI Guaranteed Annuity Plus</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="mob_search_data_Wrapper">
                    <span className="mob_search_head">Most Visited Product</span>
                    <div className="mob_search_tags_Wrapper">
                      <a href="" className="mob_tag_a">
                        ABSLI Digishield Plan
                      </a>
                      <a href="" className="mob_tag_a">
                        ABSLI Child Future Assured Plan
                      </a>
                      <a href="" className="mob_tag_a">
                        ABSLI Guaranteed Annuity Plus
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mob_search_data_Wrapper mob_other_finance_sol_Wrapper">
                  <span className="mob_search_head">Our Financial Solutions</span>
                  <div className="fin_sol_grid">
                    <div className="fin_sol_box">
                      <img
                        src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_fin_sol1.svg"
                        height={33}
                        width={33}
                        loading="lazy"
                      />
                      <p>Life Insurance</p>
                    </div>
                    <div className="fin_sol_box">
                      <img
                        src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_fin_sol2.svg"
                        height={33}
                        width={33}
                        loading="lazy"
                      />
                      <p>Health Insurance</p>
                    </div>
                    <div className="fin_sol_box">
                      <img
                        src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_fin_sol3.svg"
                        height={33}
                        width={33}
                        loading="lazy"
                      />
                      <p>Mutual Funds</p>
                    </div>
                    <div className="fin_sol_box">
                      <img
                        src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_fin_sol4.svg"
                        height={33}
                        width={33}
                        loading="lazy"
                      />
                      <p>Stock &amp; Securities</p>
                    </div>
                    <div className="fin_sol_box">
                      <img
                        src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_fin_sol5.svg"
                        height={33}
                        width={33}
                        loading="lazy"
                      />
                      <p>Housing Finance</p>
                    </div>
                    <div className="fin_sol_box">
                      <img
                        src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_fin_sol6.svg"
                        height={33}
                        width={33}
                        loading="lazy"
                      />
                      <p>Personal Finance</p>
                    </div>
                    <div className="fin_sol_box">
                      <img
                        src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/ico_fin_sol7.svg"
                        height={33}
                        width={33}
                        loading="lazy"
                      />
                      <p>SME Finance</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {bannerCountdownItem && <BottomTicker bannerCountdownItem={bannerCountdownItem} />}
    </div>
  );
};

export default MobileNavigation;
