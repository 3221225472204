import React, { useState } from 'react';
import { Link, Image, Text } from '@sitecore-jss/sitecore-jss-react';
import GraphQLData from '../../lib/GraphQLData';
import { loader as gqlLoader } from 'graphql.macro';
import { withTranslation } from 'react-i18next';

const graphQLQuery = gqlLoader('./SideMenu-OurCompanies.graphql');

const SideMenuOurCompanies = (props) => {
  const graphQLResult = props?.results?.item; 

  return (
    <>
      <div
        className="modal fade right_pos"
        id="our_companies_modal"
        tabIndex="-1"
        aria-labelledby="our_companies_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-slideout-right">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-header">
              <div className="modal-title modal-title-oc" id="our_companies_modalLabel">
                {props.t('OurCompanies')}
              </div>
            </div>
            <div className="modal-body">
              {graphQLResult?.companies?.map((x, i) => {
                return (
                  <a href={x?.link?.url} key={i}>
                    <div className="modal_redirects_Wrapper">
                      <span className="">
                        <Image field={x.icon} loading="lazy" />
                      </span>
                      <Text field={x.companyName} tag="div" className="modal_redirects_heading" editable={true} encode={false} />
                      <img
                        className="redirect_ico"
                        src="https://abconeprod.azureedge.net/dist/ABCD/assets/images/category/arrow_up_reidrect.svg"
                      />
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(
  GraphQLData(graphQLQuery, { name: 'results' })(SideMenuOurCompanies)
);
