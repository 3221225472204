import React, { useState, useEffect } from 'react';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
const FooterQuery = gqlLoader('./Footer.graphql');

const Footer = (props) => {
  const footerInfo = props?.footer?.footer_info?.children[0];

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    var mobileaccordionItems = document.querySelectorAll('.footer_mobile_acc_item');

    mobileaccordionItems.forEach(function (item) {
      item.addEventListener('click', function () {
        const isActive = this.classList.contains('mobile-border-radius');

        mobileaccordionItems.forEach(function (el) {
          el.classList.remove('mobile-border-radius');
        });

        if (!isActive) {
          this.classList.add('mobile-border-radius');
        }
      });
    });
  }, []);

  return (
    <footer>
      <div className="footer_accordion accordion grey_footer_accordion footer_content_wrap our_subsidiaries_footer">
        <div className="accordion-item">
          <div className="accordion-header">
            <button className="accordion-button" type="button">
              <Text field={footerInfo?.SubsidiariesTitle} editable />
            </button>
          </div>
          <div className="accordion-collapse">
            <div className="accordion-body">
              <div className="footer_acc_links">
                <ul className="list-inline">
                  {props?.footer?.our_subsidiaries?.companies.map((company, index) => (
                    <li className="list-inline-item" key={index}>
                      <a href={company?.link?.url} rel="noopener noreferrer">
                        {company?.companyName?.value}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main_gray_footer_wrapper">
        <div className="gray_footer_brand">
          <a href={footerInfo?.LogoCTA?.url} target={footerInfo?.LogoCTA?.target}>
            <Image field={footerInfo?.Logo} loading="lazy" />
          </a>
          <div className="footer_contact_info">
            <div className="fci_Wrp">
              <span>
                <Image field={footerInfo?.Icon} loading="lazy" />
              </span>
              <div>
                <p>{footerInfo?.TollText?.value}</p>
                <a href={footerInfo?.IconCTA?.url.replace(/^http:\/\/tel:/, 'tel:')}>
                  {footerInfo?.IconCTA?.text}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="grey_wrapper_tabs">
          <div className="footer_right_Wrapper d-none  d-lg-block">
            <ul className="nav " id="footer_tabs" role="tablist">
              {props?.footer?.footer?.primary?.map((item, index) => (
                <li className="nav-item" key={index} role="presentation">
                  <button
                    className={`nav-link ${index === 0 ? 'active' : ''}`}
                    id={`pills-footer-grey${index + 1}-tab`}
                    data-bs-toggle="pill"
                    data-bs-target={`#pills-footer-grey${index + 1}`}
                    type="button"
                    role="tab"
                    aria-controls={`pills-footer-grey${index + 1}`}
                    aria-selected={index === 0 ? 'true' : 'false'}
                  >
                    {item.menuTitle.value}
                  </button>
                </li>
              ))}
            </ul>
            <div className="tab-content" id="footer_tabsContent">
              {props?.footer?.footer?.primary?.map((item, index) => (
                <div
                  className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                  id={`pills-footer-grey${index + 1}`}
                  role="tabpanel"
                  aria-labelledby={`pills-footer-grey${index + 1}-tab`}
                  key={index}
                >
                  <div className="footer_acc_links">
                    <ul className="list-inline">
                      {item.tabs.map((tab, tabIndex) => (
                        <li className="list-inline-item" key={tabIndex}>
                          <a href={tab.menuLink.url}>{tab.menuTitle.value}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mobile_accordion_Wrapper grey_mobile_acc_wrapper d-lg-none">
          <div className="footer_accordion accordion " id="grey_mobile_footer_accordion">
            {props?.footer?.footer?.primary?.map((item, index) => (
              <div
                className={`accordion-item footer_mobile_acc_item ${
                  activeIndex === index ? 'mobile-border-radius' : ''
                }`}
                key={index}
              >
                <div
                  className="accordion-header"
                  id={`grey_mobile_footer_accordion_panels-heading${index + 1}`}
                >
                  <button
                    className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#grey_mobile_footer_accordion_panels-collapse${index + 1}`}
                    aria-expanded={index === 0 ? 'true' : 'false'}
                    aria-controls={`grey_mobile_footer_accordion_panels-collapse${index + 1}`}
                    onClick={() =>
                      setActiveIndex((prevIndex) => (prevIndex === index ? null : index))
                    }
                  >
                    {item.menuTitle.value}
                  </button>
                </div>
                <div
                  id={`grey_mobile_footer_accordion_panels-collapse${index + 1}`}
                  className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                  aria-labelledby={`grey_mobile_footer_accordion_panels-heading${index + 1}`}
                  data-bs-parent="#grey_mobile_footer_accordion"
                >
                  <div className="accordion-body">
                    <div className="mobile_url_acc_wrapper">
                      <ul>
                        {item?.tabs?.map((tab, tabIndex) => (
                          <li key={tabIndex}>
                            <a href={tab.menuLink.url}>{tab.menuTitle.value}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="download_app_wrapper">
          {footerInfo?.DownloadIcon?.src && (
            <img src={footerInfo?.DownloadIcon?.src} className="abcd_logo" loading="lazy" />
          )}
          <p>
            <Text field={footerInfo?.DownloadText} />
          </p>
          <ul className="list-inline">
            <li className="list-inline-item">
              <a href={footerInfo?.PlayStore?.url}>
                <img src={footerInfo?.PlayStoreIcon?.src} loading="lazy" />{' '}
                {footerInfo?.PlayStore?.text}
              </a>
            </li>
            <li className="list-inline-item">
              <a href={footerInfo?.AppStore?.url}>
                <img src={footerInfo?.AppStoreIcon?.src} loading="lazy" />{' '}
                {footerInfo?.AppStore?.text}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer_accordion accordion grey_footer_accordion" id="footer_accordion">
        <RichText className="accordion-item" field={footerInfo?.Address} />
        <RichText className="accordion-item" field={footerInfo?.WarningText} />
      </div>
      <div className="footer_red_strip">
        <div className="copyright_div">
          <p>{footerInfo?.RedRibonText?.value}</p>
        </div>
        <div className="footer_strip_socil">
          <ul className="list-inline">
            {props?.footer?.follow_us?.children.map((follow, index) => (
              <li className="list-inline-item" key={index}>
                <a href={follow?.menuLink?.url}>
                  <img src={follow?.menuIcon?.src} loading="lazy" />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default GraphQLData(FooterQuery, { name: 'footer' })(Footer);
