import React, { useState, useEffect } from 'react';

const TopNavigation = ({ topNavigationProps, onDropdownChange }) => {
  const [dropdownOpen, setDropdownOpen] = useState(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!e.target.closest('.nav_bar_wrapper')) {
        setDropdownOpen(null);
      }
    };

    document.body.addEventListener('click', handleOutsideClick);

    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const toggleDropdown = (index) => {
    setDropdownOpen((prevState) => (prevState === index ? null : index));
  };

  useEffect(() => {
    onDropdownChange(dropdownOpen);
  }, [dropdownOpen, onDropdownChange]);

  return (
    <div className="sec_navigation_wrapper">
      <ul className="sec_left_nav">
        {topNavigationProps?.children[1].children
          ?.slice(0, -1)
          .filter((item) => item.mobileOnly.value !== '1')
          .map((child, index) => {
            const isActive = dropdownOpen === index;
            const overlayId =
              child?.displayName === 'CORPORATES' ||
              child?.displayName === 'Corporates' ||
              child?.displayName === 'corporates'
                ? 'corporate-overlay'
                : child?.displayName === 'ADVISORS' ||
                  child?.displayName === 'Advisors' ||
                  child?.displayName === 'advisors'
                ? 'advisor-overlay'
                : '';

            return (
              <li
                key={child.id}
                className={`${child.mobileOnly?.value === '1' ? 'mobile-only' : ''} ${
                  isActive ? 'active' : ''
                } ${child.top_nav_tab?.length > 0 ? 'top_head_has_Drop advisor' : ''} ${
                  index === 0 ? 'active' : ''
                }`}
              >
                <div
                  className="overlay-div"
                  id={overlayId}
                  style={{ display: isActive ? 'block' : 'none' }}
                  onClick={() => setDropdownOpen(null)}
                ></div>
                <a
                  href={child.menuLink?.url || 'javascript:;'}
                  className={`nav-link ${isActive ? 'active-openTab' : ''}`}
                  onClick={() => {
                    toggleDropdown(index);
                  }}
                  id={
                    child?.displayName === 'CORPORATES' ||
                    child?.displayName === 'Corporates' ||
                    child?.displayName === 'corporates'
                      ? 'corporatesAnchor'
                      : child?.displayName === 'ADVISORS' ||
                        child?.displayName === 'Advisors' ||
                        child?.displayName === 'advisors'
                      ? 'corporatesAnchor2'
                      : ''
                  }
                  target={child.menuLink?.url ? '_blank' : ''}
                  rel={child.menuLink?.url ? 'noopener noreferrer' : ''}
                >
                  {child.menuTitle?.value}
                  {child.top_nav_tab?.length > 0 && <span className="chevron_right_dark"></span>}
                </a>
                {isActive && (
                  <div
                    className={`top_head_dropdown_grid show ${
                      child.top_nav_tab.length > 3 ? 'grid4-template' : ''
                    }`}
                  >
                    {child.top_nav_tab.map((tab, tabIndex) => (
                      <ul key={tabIndex}>
                        <span className="dropdow_head">{tab.Heading?.value}</span>
                        {tab.top_nav_tab_quicklinks.map((link, linkIndex) => (
                          <li key={linkIndex}>
                            <a
                              href={link.Link?.url}
                              target={link.Link?.target}
                              onClick={() => {
                                toggleDropdown(index);
                              }}
                            >
                              {link.Label?.value}
                            </a>
                          </li>
                        ))}
                      </ul>
                    ))}
                  </div>
                )}
              </li>
            );
          })}
      </ul>
      <ul className="sec_right_nav">
        {topNavigationProps?.children[1].children
          .slice(topNavigationProps?.children[1].children.length - 1)
          .map((child) => (
            <li key={child.id} className="nav_contact">
              <a href="javascript:;">
                <img
                  src={child.menuIcon?.src}
                  alt="Telephone Icon"
                  width="100%"
                  height="100%"
                  loading="lazy"
                />
                {child.menuTitle?.value}
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default TopNavigation;
