import React, { useState, useEffect } from 'react';

const ScrollTop = () => {
  const [showScrollTop, setShowScrollTop] = useState(false);

  const handleScroll = () => {
    if (typeof window !== 'undefined' && window.scrollY > 200) {
      setShowScrollTop(true)
    } else {
      setShowScrollTop(false);
    }
  };

  const handleClick = () => {
    if (typeof window !== 'undefined' && window.scrollY !== 0) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 5);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className={`btnScrollTop ${showScrollTop ? 'activated' : ''}`} onClick={handleClick}></div>
  );
};

export default ScrollTop;
