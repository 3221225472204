import React from 'react';
import { Helmet } from 'react-helmet';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Header from '../components/navigation/Header';
import Footer from '../components/navigation/Footer';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../lib/GraphQLData';
const NotFoundQuery = gqlLoader('./NotFound.graphql');

const NotFound = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="stylesheet" type="text/css" href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/bootstrap.min.css" media="all" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/swiper-bundle.min.css"
          media="all"
        />
        <link rel="stylesheet" type="text/css" href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/aos.css" media="all" />
        <link rel="stylesheet" type="text/css" href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/abc_footer.css" media="all" />
        <link
          key="nouislider"
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/noUiSlider/15.7.1/nouislider.css"
        />
        <link
          key="style"
          rel="stylesheet"
          type="text/css"
          href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/style.css"
          media="all"
        />
        <link
          key="categorypage-style"
          rel="stylesheet"
          type="text/css"
          href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/categorypage-style.css"
          media="all"
        />
        <link
          key="navigation"
          rel="stylesheet"
          type="text/css"
          href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/navigation.css"
          media="all"
        />
        <link
          key="common"
          rel="stylesheet"
          type="text/css"
          href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/common.css"
          media="all"
        />
        <link rel="stylesheet" type="text/css" href="https://abconeprod.azureedge.net/dist/ABCD/assets/css/jss-override.css" media="all" />
      </Helmet>
      <div className="ticker-header-parent tickerOpen-parent">
        <Header />
      </div>
      <RichText field={props?.notfound?.item?.NotFound} />
      <Footer />
    </React.Fragment>
  );
};

export default GraphQLData(NotFoundQuery, { name: 'notfound' })(NotFound);
