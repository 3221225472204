import $ from 'jquery';

var optionsArray = [
  {
    category: 'Loans',
    options: [
      'Housing Loans',
      'Home Loan',
      'Balance Transfer',
      'Top up Home Loan',
      'Loan Against Property',
      'Personal Loans',
      'Personal Loan',
      'Flexi Loan',
      'Business Loans',
      'Unsecured Business Loan',
      'Secured Business Loan',
      'Housing Finance',
      'Personal Finance',
      'SME Finance',
      'Business Loan',
      'SME Loan',
    ],
  },
  {
    category: 'Insurance',
    options: [
      'Life Insurance',
      'Term Insurance',
      'Retirement Plans',
      'Savings Plans',
      'Saving Plans',
      'Child Plans',
      'ULIP Plans',
      'Health Insurance',
      'Health & Wellness Plans',
      'Super Top-up Plans',
      'Motor Insurance',
      'Bike Insurance',
      'Car Insurance',
    ],
  },
  {
    category: 'Investments',
    options: [
      'Mutual Funds',
      'Debt Funds',
      'Equity Funds',
      'Hybrid Funds',
      'Retirement Funds',
      'Children’s Funds',
      'Exchange Traded Funds',
      'Fund Of Funds',
      'Index Funds',
      'Stocks & Securities',
      'Stock & Securities',
      'Equity Trading',
      'Margin Trading Facility',
      'Bonds',
      'FD & Digital Gold',
      'Fixed Deposit',
      'Digital Gold',
      'Tax Solutions',
      'Tax Planning',
      'Share Market',
    ],
  },
  {
    category: 'Payments',
    options: [
      'Payment for Individuals',
      'Pay Bills',
      'Pay for Anything',
      'Pay Anyone',
      'Pay on Call',
      'Cards',
      'Credit Cards',
    ],
  },
  {
    category: 'Tools',
    options: [
      'My Track',
      'Credit Track',
      'Credit Score',
      'Free Credit Score',
      'Check Free Credit Score',
      'Health Track',
      'Portfolio Track',
      'Portfolio Tracker',
      'Spend Track',
    ],
  },
];

var optionMappings = {
  'retirement-and-pension-plans': 'retirement-plans',
  'home-loan-balance-transfer': 'balance-transfer',
  'solutions-oriented-retirement-fund': 'retirement-fund',
  'solutions-oriented-children-fund': 'childrens-fund',
  etfs: 'exchange-traded-funds',
  equity: 'equity-trading',
  'sovereign-gold-bonds': 'bonds',
  'term-insurance-plans': 'term-insurance',
  'top-up-plans': 'super-top-up-plans',
  'check-free-credit-score': 'credit-track',
  'portfolio-tracker': 'portfolio-track',
};

function getPageType() {
  var currentUrl = window.location.href;
  var urlParts = currentUrl.split('/');

  // Extract relevant path segments, excluding the protocol and domain parts
  var pathSegments = urlParts.slice(3);

  // Remove any unwanted segments like 'abcd'
  pathSegments = pathSegments.filter((segment) => segment !== 'abcd' || 'digital');

  // If there are no path segments, return 'abcd'
  if (pathSegments.length === 0 || (pathSegments.length === 1 && pathSegments[0] === '')) {
    return 'abcd';
  }

  // Join the path segments with underscores, replacing hyphens with underscores
  var pageType = pathSegments.join('_').replace(/-/g, '_');

  // Remove trailing underscore if it exists
  pageType = pageType.replace(/_$/, '');

  return pageType;
}

// Utility function to get the page type and remove query parameters
function getCleanedPageType() {
  var pageType = getPageType().replace(/[?&].*$/, '');

  // Check if pageType starts with 'abcd_'
  if (pageType.startsWith('abcd_')) {
    // Remove 'abcd_' from the start
    pageType = pageType.substring(5);
  }

  // If pageType is still 'abcd', keep it as 'abcd'
  if (pageType === 'abcd') {
    return pageType;
  }

  return pageType;
}

const formatString = (str) =>
  str
    ? str
        .toLowerCase()
        .replace(/[\s/-]+/g, '_')
        .replace(/[^a-z0-9_]+/g, '')
        .replace(/_+/g, '_')
    : 'NA';

function pushDataLayerEventVerifyOTP(
  eventName,
  ctaText,
  sectionTitle,
  selectedOption,
  position,
  additionalData = {}
) {
  let firstSegment = '';

  // Determine firstSegment if selectedOption is null and position is right_first_fold
  if (position === 'right_first_fold') {
    const urlSegments = window.location.pathname.split('/');
    const nonEmptySegments = urlSegments.filter((segment) => segment !== '');
    firstSegment = nonEmptySegments[nonEmptySegments.length - 1];
    optionsArray.forEach(function (mapping) {
      mapping.options.forEach(function (option) {
        const formattedOption = option.toLowerCase().replace(/\s+/g, '-');
        if (urlSegments.includes(formattedOption)) {
          sectionTitle = mapping.category.trim().toLowerCase().replace(/\s+/g, '_');
        }
      });
    });
  }

  const dataLayerObject = {
    event: eventName,
    user_id: 'NA',
    user_status: 'non_logged_in',
    page_type: getCleanedPageType(),
    CTA_text: formatString(ctaText),
    option_selected:
      selectedOption === null
        ? formatString(optionMappings[firstSegment] || firstSegment)
        : formatString(optionMappings[selectedOption] || selectedOption),
    section_title: formatString(sectionTitle),
    visitor_id: 'NA',
    guest_id: 'NA',
    golden_id: 'NA',
    clevertap_id: 'NA',
    appsflyer_id: 'NA',
    position: formatString(position),
    distinct_id: 'NA',
    ...additionalData,
  };

  // Push the object to dataLayer
  dataLayer.push(dataLayerObject);
  clevertap.event.push(dataLayerObject);

  // console.log(`${eventName}:`, dataLayerObject);
}

// 4. When user selects any option from main navigation
export function handleMainNavClick(sectionTitle, cardTitle, ctaText) {
  const dataLayerObject = {
    event: 'main_nav_click',
    user_id: 'NA',
    user_status: 'non_logged_in',
    page_type: getCleanedPageType(),
    CTA_text: formatString(ctaText) === 'download' ? 'download_abcd_app' : formatString(ctaText),
    section_title: formatString(sectionTitle),
    card_title: formatString(cardTitle),
    guest_id: 'NA',
    golden_id: 'NA',
    clevertap_id: 'NA',
    appsflyer_id: 'NA',
    distinct_id: 'NA',
  };
  // Push the object to dataLayer
  dataLayer.push(dataLayerObject);
  clevertap.event.push(dataLayerObject);

  // Console log the dataLayer object
  // console.log('main_nav_click:', dataLayerObject);
}

// 6. When user clicks on the CTA from the card present in the main navigation
export function handleMainNavCTAClick(sectionTitle, cardTitle, ctaText) {
  const dataLayerObject = {
    event: 'main_nav_card_cta_click',
    user_id: 'NA',
    user_status: 'non_logged_in',
    page_type: getCleanedPageType(),
    CTA_text: formatString(ctaText),
    section_title: formatString(sectionTitle),
    card_title: formatString(cardTitle),
    visitor_id: 'NA',
    guest_id: 'NA',
    golden_id: 'NA',
    clevertap_id: 'NA',
    appsflyer_id: 'NA',
    distinct_id: 'NA',
  };

  // Push the object to dataLayer
  dataLayer.push(dataLayerObject);
  clevertap.event.push(dataLayerObject);

  // Console log the dataLayer object
  // console.log('banner_cta_click:', dataLayerObject);
}

// 7. When user clicks on any option from articles from the main navigation
export function handleArticleClick(ctaText, sectionTitle) {
  // List of potential selectors for hovered elements
  var potentialSelectors = [
    '.loan_megamenu_wrapper.megamenu_open .loan_lists .list_hovered a',
    '.investment_megamenu_wrapper.megamenu_open .investment_lists .list_hovered a',
    '.insurance_megamenu_wrapper.megamenu_open .insurance_lists .list_hovered a',
    '.resources_megamenu_wrapper.megamenu_open .list_hovered a',
  ];

  var card_title = '';
  for (var i = 0; i < potentialSelectors.length; i++) {
    var hoveredElement = $(potentialSelectors[i]);
    if (hoveredElement.length > 0) {
      card_title = hoveredElement.text().trim();
      break;
    }
  }

  // If no hovered element is found, use the text of the first list item in each menu
  if (card_title === '') {
    var fallbackSelectors = [
      '.loan_megamenu_wrapper.megamenu_open .loan_lists li:first-child a',
      '.investment_megamenu_wrapper.megamenu_open .investment_lists li:first-child a',
      '.insurance_megamenu_wrapper.megamenu_open .insurance_lists li:first-child a',
      '.resources_megamenu_wrapper.megamenu_open li:first-child a',
    ];
    for (var j = 0; j < fallbackSelectors.length; j++) {
      var firstElement = $(fallbackSelectors[j]);
      if (firstElement.length > 0) {
        card_title = firstElement.text().trim();
        break;
      }
    }
  }

  const dataLayerObject = {
    event: 'main_nav_article_click',
    user_id: 'NA',
    user_status: 'non_logged_in',
    page_type: getCleanedPageType(),
    CTA_text: formatString(ctaText),
    section_title: formatString(sectionTitle),
    card_title: formatString(card_title),
    visitor_id: 'NA',
    guest_id: 'NA',
    golden_id: 'NA',
    clevertap_id: 'NA',
    appsflyer_id: 'NA',
    distinct_id: 'NA',
  };

  // Push the object to dataLayer
  dataLayer.push(dataLayerObject);
  clevertap.event.push(dataLayerObject);

  // Console log the dataLayer object
  // console.log('main_nav_article_click:', dataLayerObject);
}

// 9. When user clicks on send/get OTP after filling the details in the lead form
export function handleLeadFormOtpCtaClick(ctaText, sectionTitle, selectedOption, position) {
  let firstSegment = '';

  // Determine firstSegment if selectedOption is null and position is right_first_fold
  if (position === 'right_first_fold') {
    const urlSegments = window.location.pathname.split('/');
    const nonEmptySegments = urlSegments.filter((segment) => segment !== '');
    firstSegment = nonEmptySegments[nonEmptySegments.length - 1];
    optionsArray.forEach(function (mapping) {
      mapping.options.forEach(function (option) {
        const formattedOption = option.toLowerCase().replace(/\s+/g, '-');
        if (urlSegments.includes(formattedOption)) {
          sectionTitle = mapping.category.trim().toLowerCase().replace(/\s+/g, '_');
        }
      });
    });
  }

  const dataLayerObject = {
    event: 'lead_form_otp_cta_click',
    user_id: 'NA',
    user_status: 'non_logged_in',
    page_type: getCleanedPageType(),
    CTA_text: formatString(ctaText),
    option_selected:
      selectedOption === null
        ? formatString(optionMappings[firstSegment] || firstSegment)
        : formatString(optionMappings[selectedOption] || selectedOption),
    section_title: formatString(sectionTitle),
    visitor_id: 'NA',
    guest_id: 'NA',
    golden_id: 'NA',
    clevertap_id: 'NA',
    appsflyer_id: 'NA',
    position: formatString(position),
    distinct_id: 'NA',
  };

  // Push the object to dataLayer
  dataLayer.push(dataLayerObject);
  clevertap.event.push(dataLayerObject);

  // Console log the dataLayer object
  // console.log('lead_form_otp_cta_click:', dataLayerObject);
}

// 10. When user clicks on "Change Number" or "Resend OTP" CTA in the lead form
export function handleLeadFormCtaClick(ctaText, sectionTitle, selectedOption, position) {
  let firstSegment = '';

  // Determine firstSegment if selectedOption is null and position is right_first_fold
  if (position === 'right_first_fold') {
    const urlSegments = window.location.pathname.split('/');
    const nonEmptySegments = urlSegments.filter((segment) => segment !== '');
    firstSegment = nonEmptySegments[nonEmptySegments.length - 1];
    optionsArray.forEach(function (mapping) {
      mapping.options.forEach(function (option) {
        const formattedOption = option.toLowerCase().replace(/\s+/g, '-');
        if (urlSegments.includes(formattedOption)) {
          sectionTitle = mapping.category.trim().toLowerCase().replace(/\s+/g, '_');
        }
      });
    });
  }

  const dataLayerObject = {
    event: 'lead_form_cta_interaction',
    user_id: 'NA',
    user_status: 'non_logged_in',
    page_type: getCleanedPageType(),
    CTA_text: formatString(ctaText),
    option_selected:
      selectedOption === null
        ? formatString(optionMappings[firstSegment] || firstSegment)
        : formatString(optionMappings[selectedOption] || selectedOption),
    section_title: formatString(sectionTitle),
    visitor_id: 'NA',
    guest_id: 'NA',
    golden_id: 'NA',
    clevertap_id: 'NA',
    appsflyer_id: 'NA',
    position: formatString(position),
    distinct_id: 'NA',
  };
  // Push the object to dataLayer
  dataLayer.push(dataLayerObject);
  clevertap.event.push(dataLayerObject);

  // Console log the dataLayer object
  // console.log('lead_form_cta_interaction:', dataLayerObject);
}

// 11. When user clicks on "Verfiy to proceed" CTA in the lead form
export function handleVerifyBtnEvent(ctaText, sectionTitle, selectedOption, position) {
  pushDataLayerEventVerifyOTP(
    'lead_form_verify_otp_click',
    ctaText,
    sectionTitle,
    selectedOption,
    position,
    null
  );
}

// 12. When user clicks on "Verfiy to proceed" CTA in the lead form and leadId generated
export function handleLeadSuccessEvent(ctaText, sectionTitle, selectedOption, position, leadId) {
  if (leadId) {
    pushDataLayerEventVerifyOTP(
      'lead_form_success',
      ctaText,
      sectionTitle,
      selectedOption,
      position,
      { lead_id: leadId }
    );
  }
}

// 17. When user clicks on loans, insurance, investments and payments in the lead form section
export function handleProductNavigationInteraction(ctaText, position) {
  const dataLayerObject = {
    event: 'product_navigation_interaction',
    user_id: 'NA',
    user_status: 'non_logged_in',
    page_type: getCleanedPageType(),
    CTA_text: ctaText.toLowerCase().replace(/\s+/g, '_').replace(/\//g, '_'),
    position: position,
    visitor_id: 'NA',
    guest_id: 'NA',
    golden_id: 'NA',
    clevertap_id: 'NA',
    appsflyer_id: 'NA',
    distinct_id: 'NA',
  };
  // Push the object to dataLayer
  dataLayer.push(dataLayerObject);
  clevertap.event.push(dataLayerObject);

  // Console log the dataLayer object
  // console.log('product_navigation_interaction:', dataLayerObject);
}

// 18. When user clicks on know more or enquire now CTA from any cards
export function handleProductCTAInteraction(ctaText, cardTitle) {
  const dataLayerObject = {
    event: 'product_cta_interaction',
    user_id: 'NA',
    user_status: 'non_logged_in',
    page_type: getCleanedPageType(),
    CTA_text: ctaText.toLowerCase().replace(/\s+/g, '_').replace(/\//g, '_'),
    card_title: cardTitle.toLowerCase().replace(/\s+/g, '_').replace(/\//g, '_'),
    visitor_id: 'NA',
    guest_id: 'NA',
    golden_id: 'NA',
    clevertap_id: 'NA',
    appsflyer_id: 'NA',
    distinct_id: 'NA',
  };
  // Push the object to dataLayer
  dataLayer.push(dataLayerObject);
  clevertap.event.push(dataLayerObject);

  // Console log the dataLayer object
  // console.log('product_cta_interaction:', dataLayerObject);
}

// 21. When user clicks on the any CTA from the banner
export function handleBannerCtaClick(bannerTitle, ctaText) {
  const dataLayerObject = {
    event: 'banner_cta_click',
    user_id: 'NA',
    user_status: 'non_logged_in',
    page_type: getCleanedPageType(),
    banner_title: bannerTitle
      .replace(/<[^>]*>/g, '')
      .replace(/\s+/g, '_')
      .toLowerCase()
      .replace(/_+$/, ''),
    CTA_text: ctaText
      .toLowerCase()
      .replace(/[\s/-]+/g, '_')
      .replace(/[^a-z0-9_]+/g, '')
      .replace(/_+/g, '_'),
    visitor_id: 'NA',
    guest_id: 'NA',
    golden_id: 'NA',
    clevertap_id: 'NA',
    appsflyer_id: 'NA',
    distinct_id: 'NA',
  };

  // Push the object to dataLayer
  dataLayer.push(dataLayerObject);
  clevertap.event.push(dataLayerObject);

  // Console log the dataLayer object
  // console.log('banner_cta_click:', dataLayerObject);
}

// 22. When user clicks on play button of the video from the cards
export function handleVideoPlayClick(sectionTitle) {
  const dataLayerObject = {
    event: 'video_play_click',
    user_id: 'NA',
    user_status: 'non_logged_in',
    page_type: getCleanedPageType(),
    section_title: sectionTitle
      .replace(/<[^>]*>/g, '')
      .replace(/\s+/g, '_')
      .toLowerCase()
      .replace(/_+$/, ''),
    visitor_id: 'NA',
    guest_id: 'NA',
    golden_id: 'NA',
    clevertap_id: 'NA',
    appsflyer_id: 'NA',
    distinct_id: 'NA',
  };

  // Push the object to dataLayer
  dataLayer.push(dataLayerObject);
  clevertap.event.push(dataLayerObject);

  // Console log the dataLayer object
  // console.log('video_play_click:', dataLayerObject);
}

// 23. When user clicks on download app modal cta
export function handleAppDownloadCTAClick(ctaText, sectionTitle) {
  const dataLayerObject = {
    event: 'app_download_cta',
    user_id: 'NA',
    user_status: 'non_logged_in',
    page_type: getCleanedPageType(),
    CTA_text: formatString(ctaText),
    section_title: formatString(sectionTitle),
    visitor_id: 'NA',
    guest_id: 'NA',
    golden_id: 'NA',
    clevertap_id: 'NA',
    appsflyer_id: 'NA',
    distinct_id: 'NA',
  };

  // Push the object to dataLayer
  dataLayer.push(dataLayerObject);
  clevertap.event.push(dataLayerObject);

  // Console log the dataLayer object
  // console.log('app_download_cta:', dataLayerObject);
}

// 30. When a user interacts with tabs from any section
export function handleSectionTabsClick(sectionTitle, ctaText) {
  const dataLayerObject = {
    event: 'section_tabs_click',
    user_id: 'NA',
    user_status: 'non_logged_in',
    page_type: getCleanedPageType(),
    section_title: sectionTitle
      .replace(/<[^>]*>/g, '')
      .replace(/\s+/g, '_')
      .toLowerCase()
      .replace(/_+$/, ''),
    CTA_text: ctaText
      .toLowerCase()
      .replace(/[\s/-]+/g, '_')
      .replace(/[^a-z0-9_]+/g, '')
      .replace(/_+/g, '_'),
    visitor_id: 'NA',
    guest_id: 'NA',
    golden_id: 'NA',
    clevertap_id: 'NA',
    appsflyer_id: 'NA',
    distinct_id: 'NA',
  };

  // Push the object to dataLayer
  dataLayer.push(dataLayerObject);
  clevertap.event.push(dataLayerObject);

  // Console log the dataLayer object
  // console.log('section_tabs_click:', dataLayerObject);
}

// 41. When a user clicks on any CTA from the plan card
export function handlePlanCardCTAClick(ctaText, cardTitle) {
  const dataLayerObject = {
    event: 'plan_card_cta_click',
    user_id: 'NA',
    user_status: 'non_logged_in',
    page_type: getCleanedPageType(),
    CTA_text: ctaText.toLowerCase().replace(/\s+/g, '_').replace(/\//g, '_'),
    card_title: cardTitle.toLowerCase().replace(/\s+/g, '_').replace(/\//g, '_'),
    visitor_id: 'NA',
    guest_id: 'NA',
    golden_id: 'NA',
    clevertap_id: 'NA',
    appsflyer_id: 'NA',
    distinct_id: 'NA',
  };
  // Push the object to dataLayer
  dataLayer.push(dataLayerObject);
  clevertap.event.push(dataLayerObject);

  // Console log the dataLayer object
  // console.log('plan_card_cta_click:', dataLayerObject);
}
