import React, { useState } from 'react';
import { Link, Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import GraphQLData from '../../lib/GraphQLData';
import { loader as gqlLoader } from 'graphql.macro';
import { withTranslation } from 'react-i18next';
import SubscribeToNewsLetter from '../../lib/subscribe-to-news-letter';

const graphQLQuery = gqlLoader('./SideMenuDownloadApp.graphql');

const SideMenuDownloadApp = (props) => {
  const graphQLResult = props?.data?.item?.children[0];

  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const validateAndSubmit = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || email.length > 50 || !re.test(email)) {
      setIsValid(false);
      setIsEmpty(!email);
      return false;
    } else {
      setIsValid(true);
      setIsEmpty(false);
      return true;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateAndSubmit(email)) {
      try {
        const message = await SubscribeToNewsLetter(email);
        if (message === 'Email is already Subscribed') {
          setResponseMessage(message);
          setIsValid(true);
          setIsEmpty(false);
          setEmail('');
        } else {
          setResponseMessage(message + '!');
        }
      } catch (error) {
        setResponseMessage('Unexpected error occurred');
        setIsValid(false);
        setIsEmpty(false);
        setEmail('');
      }
    } else {
      setIsValid(false);
      setIsEmpty(!email);
      setResponseMessage('');
    }
  };
  return (
    <div
      className="modal fade metaverse_right_pos get_early_access"
      id="metaverse_modal_x1"
      tabIndex={-1}
      aria-labelledby="metaverse_modal_modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-slideout-right">
        <div className="modal-content">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          <div className="modal-body">
            <div className="modal_wrapper_top">
              <div className="modal-header metaverse_right_pos_head">
                <div className="top-logo-video">
                  <Image field={graphQLResult?.image1} loading="lazy" />
                </div>
                <RichText
                  tag="div"
                  className="modal-title"
                  id="metaverse_modal_modalLabel"
                  field={graphQLResult?.heading}
                />
                <p className="popup-p">
                  <Text field={graphQLResult?.subheading} />
                </p>
              </div>
              <div className="modal_form_wrapper">
                <div className="boxCol-content"></div>
                <div className="download-link-form">
                  <input
                    type="text"
                    placeholder={props.t('FormPlaceholderEmail')}
                    className="input-form-text"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="img-absolute2">
                    <button className="btn btn-app" onClick={handleSubmit}>
                      {props.t('NotifyMe')}
                    </button>
                  </div>
                  {!isValid && !isEmpty && (
                    <div className="pt-2 text-danger">
                      <small>{props.t('ValidMailAddress')}</small>
                    </div>
                  )}
                  {isEmpty && (
                    <div className="pt-2 text-danger">
                      <small>{props?.t('EmailRequired')}</small>
                    </div>
                  )}
                  {!isEmpty && responseMessage && (
                    <div
                      className={`pt-2 ${responseMessage.trim() === props.t('EmailAddedToSubscription')
                          ? 'text-success'
                          : 'text-danger'
                        }`}
                    >
                      <small>{responseMessage}</small>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal_body_bottom">
              <Image className="mob-img-mb" field={graphQLResult?.image2} loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(GraphQLData(graphQLQuery, { name: 'data' })(SideMenuDownloadApp));
